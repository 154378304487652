import React from 'react';

import Draggable from 'react-draggable';

import { useIntl } from 'react-intl';
import Tooltip from '@material-ui/core/Tooltip';

import translation from './../../utils/translation';

import { BreakoutRoomIcon } from './../../components/BreakoutRoomIcon';

import { theme, styled, AimTypography, CustomIntl } from '@aim/components';
import ScreenShare from './../../components/icons/ScreenShare';
import WebcamVideoDisabled from './../../components/icons/WebcamVideoDisabled';
import WebcamVideo from './../../components/icons/WebcamVideo';
import Mute from './../../components/icons/Mute';
import Microphone from './../../components/icons/Microphone';

const MainContainer = styled('div')({
  width: 300,
  height: 200,
  margin: 10,
  position: 'absolute',
  zIndex: 1202,
  bottom: 60,
  right: 10,
  cursor: 'all-scroll',
});

const InnerContainer = styled('div')({
  position: 'relative',
});

const ControlsContainer = styled('div')({
  position: 'absolute',
  bottom: 10,
  right: 10,
  zIndex: 22,
});

const TextContainer = styled('div')({
  position: 'absolute',
  bottom: 15,
  left: 10,
  zIndex: 22,
});

const TextContainerInnerContainer = styled('div')({
  display: 'flex',
});

const StyledText = styled(AimTypography)({
  color: theme.colors.greyScale.white,
  marginLeft: 8,
});

const Video = styled('div')({
  width: 300,
  height: 200,
  zIndex: 21,
  border: `3px solid ${theme.colors.primary.yellow}`,
});

const ShareContent = styled('div')({
  width: '100%',
  height: '100%',
  background: `#202124`,
  color: `${theme.colors.greyScale.white}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
});

export const PublisherDraggableControls = ({
  publisherDivName,
  handleMute,
  isMicrophoneOpen,
  toggleVideo,
  isVideoEnabled,
  isScreenShared,
  shareScreenFunction,
  publisherScreen,
}) => {
  // Hooks
  const intl = CustomIntl(useIntl());
  const i18n = translation.publisherDraggableControls(intl);

  return (
    <Draggable bounds="body">
      <MainContainer>
        <InnerContainer>
          <Video style={{ display: publisherScreen ? 'block' : 'none' }}>
            <ShareContent>
              <div>
                <ScreenShare style={{ fontSize: '2rem' }} />
              </div>
              <div>
                <AimTypography
                  style={{ fontFamily: 'Roboto', fontSize: '1.2rem' }}
                >
                  {i18n.presenting.label}
                </AimTypography>
              </div>
            </ShareContent>
          </Video>
          <Video
            id={`${publisherDivName}`}
            style={{ display: publisherScreen ? 'none' : 'block' }}
          />

          <TextContainer>
            <TextContainerInnerContainer>
              <Microphone />
              <StyledText variant="caption" margin={0}>
                {i18n.you.label}
              </StyledText>
            </TextContainerInnerContainer>
          </TextContainer>
          <ControlsContainer>
            {/* Disable self video */}
            <Tooltip
              title={
                isVideoEnabled
                  ? i18n.tooltips.camera.disable.label
                  : i18n.tooltips.camera.enable.label
              }
            >
              <BreakoutRoomIcon
                variant={isVideoEnabled ? 'BRred' : 'BRtissue'}
                onClick={toggleVideo}
              >
                {isVideoEnabled ? (
                  <WebcamVideo style={{ fontSize: '1rem' }} />
                ) : (
                  <WebcamVideoDisabled style={{ fontSize: '1rem' }} />
                )}
              </BreakoutRoomIcon>
            </Tooltip>

            {/* Disable mute microphone */}
            <Tooltip
              title={
                isMicrophoneOpen
                  ? i18n.tooltips.microphone.disable.label
                  : i18n.tooltips.microphone.enable.label
              }
            >
              <BreakoutRoomIcon
                variant={isMicrophoneOpen ? 'BRred' : 'BRtissue'}
                onClick={handleMute}
              >
                {isMicrophoneOpen ? (
                  <Microphone style={{ fontSize: '1rem' }} />
                ) : (
                  <Mute style={{ fontSize: '1rem' }} />
                )}
              </BreakoutRoomIcon>
            </Tooltip>

            {/* Disable screen sharing*/}
            <Tooltip
              title={
                isScreenShared
                  ? i18n.tooltips.videoSharing.someoneSharing.label
                  : publisherScreen
                  ? i18n.tooltips.videoSharing.disable.label
                  : i18n.tooltips.videoSharing.enable.label
              }
            >
              <div style={{ display: 'initial' }}>
                <BreakoutRoomIcon
                  variant={publisherScreen ? 'BRred' : 'BRtissue'}
                  onClick={shareScreenFunction}
                  disabled={isScreenShared}
                >
                  <ScreenShare style={{ fontSize: '1rem' }} />
                </BreakoutRoomIcon>
              </div>
            </Tooltip>
          </ControlsContainer>
        </InnerContainer>
      </MainContainer>
    </Draggable>
  );
};
