import React from 'react';
import Picker from '@emoji-mart/react';

import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const EmojiPopper = ({
  onPopperClickAway,
  open,
  anchorEl,
  onAddEmoji,
  i18n,
}) => {
  return (
    <ClickAwayListener onClickAway={onPopperClickAway}>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="top-end"
        transition
        style={{ zIndex: 1202 }}
      >
        {() => (
          <Picker
            onSelect={onAddEmoji}
            theme="dark"
            title={i18n.emojiPicker.title}
            i18n={i18n.emojiPicker.i18n}
            emoji="point_up"
          />
        )}
      </Popper>
    </ClickAwayListener>
  );
};

export default React.memo(EmojiPopper);
