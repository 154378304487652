import React, { useEffect } from 'react';

import { useIntl } from 'react-intl';

import { SettingsDivider } from './SettingsDivider';
import { BreakoutRoomIcon } from '../../../components/BreakoutRoomIcon';

import AutorenewIcon from '@material-ui/icons/Autorenew';
import Tooltip from '@material-ui/core/Tooltip';

import translation from './../../../utils/translation';
import { styled } from '@aim/components';

const MainContainer = styled('div')({
  overflowY: 'auto',
  overflowX: 'hidden',
  height: 'calc(100vh - (120px + 40px))',
});

import {
  AimRadio,
  AimCheckbox,
  AimTypography,
  AimRadioButton,
  AimFormControlLabel,
  CustomIntl,
} from '@aim/components';

const TabSettings = ({
  audioInputs,
  videoInputs,
  currentAudioInput,
  currentVideoInput,
  handleInputAudio,
  handleInputVideo,
  isSoundNotificationActive,
  handleSoundNotification,
  displayControls,
  fetchDevices,
}) => {
  //Hooks
  const intl = CustomIntl(useIntl());
  const i18n = translation.tabSettings(intl);

  useEffect(() => {
    fetchDevices?.();
  }, []);

  return (
    <MainContainer>
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            paddingLeft: 10,
          }}
        >
          <Tooltip title={i18n.group.refresh.label}>
            <BreakoutRoomIcon onClick={fetchDevices} variant={'BRgray'}>
              <AutorenewIcon style={{ fontSize: '1rem' }} />
            </BreakoutRoomIcon>
          </Tooltip>
        </div>
        {displayControls ? (
          <>
            <SettingsDivider text={i18n.group.microphone.label} />
            <AimRadio
              value={currentAudioInput}
              onChange={(e) => handleInputAudio(e.target.value.toString())}
              formControlWidth="calc(100% - 10px)"
              style={{
                marginLeft: 16,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {audioInputs.map((ai, idx) => (
                <AimFormControlLabel
                  key={idx}
                  value={ai.deviceId}
                  control={<AimRadioButton />}
                  label={ai.label}
                />
              ))}
            </AimRadio>

            <SettingsDivider text={i18n.group.camera.label} />
            <AimRadio
              value={currentVideoInput || videoInputs?.[0]?.deviceId}
              onChange={(e) => handleInputVideo(e.target.value.toString())}
              formControlWidth="calc(100% - 10px)"
              style={{
                marginLeft: 16,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {videoInputs.map((ai, idx) => (
                <AimFormControlLabel
                  key={idx}
                  value={ai.deviceId}
                  control={<AimRadioButton />}
                  label={ai.label}
                />
              ))}
            </AimRadio>
          </>
        ) : null}
        <SettingsDivider text={i18n.group.notifications.label} />
        <div
          style={{
            display: 'flex',
            marginLeft: 16,
            marginTop: 10,
            alignItems: 'center',
          }}
        >
          <AimCheckbox
            variant={'white'}
            onClick={() => handleSoundNotification(!isSoundNotificationActive)}
            checked={isSoundNotificationActive}
          />
          <AimTypography margin={0} style={{ marginLeft: 8 }}>
            {i18n.group.notifications.playText.label}
          </AimTypography>
        </div>
      </div>
    </MainContainer>
  );
};

export { TabSettings };
