import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

let Microphone = (props) => (
  <SvgIcon
    {...props}
    width="12.482"
    height="19.503"
    viewBox="0 0 12.482 19.503"
    style={{
      fill: '#fff',
      fontSize: 20,
      filter: 'drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7))',
      ...props.style,
    }}
  >
    <g transform="translate(0 9.384)">
      <g transform="translate(0)">
        <path
          d="M90.818,210.1a.688.688,0,1,0-1.377,0,4.864,4.864,0,0,1-9.728,0,.688.688,0,1,0-1.377,0,6.24,6.24,0,0,0,5.553,6.218v1.836h-2.5a.688.688,0,0,0,0,1.377h6.379a.688.688,0,0,0,0-1.377h-2.5v-1.836A6.24,6.24,0,0,0,90.818,210.1Z"
          transform="translate(-78.336 -209.408)"
        />
      </g>
    </g>
    <g transform="translate(2.409)">
      <g transform="translate(0)">
        <path
          d="M135.928,0A3.839,3.839,0,0,0,132.1,3.832V10.05a3.832,3.832,0,1,0,7.663.023V3.832A3.839,3.839,0,0,0,135.928,0Z"
          transform="translate(-132.096 0)"
        />
      </g>
    </g>
  </SvgIcon>
);

export default Microphone;
