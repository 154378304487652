import React, { useState, useEffect } from 'react';

import { differenceInSeconds } from 'date-fns';

import { AimTypography, styled } from '@aim/components';

import { minTwoDigits } from './../../../utils/utility';

const variants = {
  red: { background: '#FE0C4E 0% 0% no-repeat padding-box' },
  transparent: { background: 'transparent', border: '1px solid #707070' },
};

const MainContainer = styled('div')(({ variant = 'red' }) => ({
  ...variants[variant],
  borderRadius: 4,
  height: 40,
  width: 100,
  margin: 8,
}));

const CustomTypography = styled(AimTypography)({
  font: 'normal normal medium 15px/20px Roboto',
});

const getSecondsFromNow = (date) => {
  return differenceInSeconds(date, new Date());
};

const TimeLeftComponent = ({ end }) => {
  // States
  const [secondsLeft, setSecondsLeft] = useState(0);

  // Effects
  useEffect(() => {
    if (end) {
      const nextSeconds = getSecondsFromNow(new Date(end));
      setSecondsLeft(nextSeconds);

      const interval = setInterval(() => {
        const nextSeconds = getSecondsFromNow(new Date(end));
        setSecondsLeft(nextSeconds);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [end]);

  const parseSecondsLeft = () => {
    if (secondsLeft) {
      const minutes = minTwoDigits(Math.floor(secondsLeft / 60));
      const seconds = minTwoDigits(secondsLeft % 60);

      return `${minutes}':${seconds}''`;
    }
  };

  return (
    <>
      {secondsLeft ? (
        <MainContainer variant={secondsLeft > 60 * 5 ? 'transparent' : 'red'}>
          <CustomTypography textAlign="center">
            {parseSecondsLeft()}
          </CustomTypography>
        </MainContainer>
      ) : null}
    </>
  );
};

const TimeLeft = React.memo(TimeLeftComponent);

export { TimeLeft };
