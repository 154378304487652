import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

let ScreenShare = (props) => (
  <SvgIcon
    {...props}
    width="17.187"
    height="14.054"
    viewBox="0 0 17.187 14.054"
    style={{
      fill: '#fff',
      fontSize: 40,
      filter: 'drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7))',
      ...props.style,
    }}
  >
    <g transform="translate(0 -42.765)">
      <g transform="translate(0 42.765)">
        <g transform="translate(0 0)">
          <path
            d="M15.624,42.765H1.562A1.562,1.562,0,0,0,0,44.327V53.7a1.562,1.562,0,0,0,1.562,1.562V44.327H15.624V55.264A1.562,1.562,0,0,0,17.187,53.7V44.327A1.562,1.562,0,0,0,15.624,42.765Z"
            transform="translate(0 -42.765)"
          />
        </g>
      </g>
      <g transform="translate(3.906 50.578)">
        <path
          d="M115.6,260.582l-3.483-4.086a.965.965,0,0,0-1.519,0l-3.483,4.086c-.844.99-.471,1.786.825,1.786h6.835C116.073,262.368,116.44,261.569,115.6,260.582Zm-6.622.223,2.38-2.792,2.38,2.792Z"
          transform="translate(-106.662 -256.127)"
        />
      </g>
    </g>
  </SvgIcon>
);

export default ScreenShare;
