import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

let Layout2 = (props) => (
  <SvgIcon
    {...props}
    width="41"
    height="41"
    viewBox="0 0 41 41"
    style={{
      filter: 'drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7))',
      ...props.style,
    }}
  >
    <g transform="translate(-853 -23)">
      <g transform="translate(860.519 31)">
        <g transform="translate(0 0)">
          <g
            style={{ fill: 'none', stroke: '#fff', strokeWidth: 2 }}
            transform="translate(0.083)"
          >
            <rect
              style={{ stroke: 'none', fill: 'none' }}
              width="25"
              height="25"
            />
            <rect style={{ fill: 'none' }} x="1" y="1" width="23" height="23" />
          </g>
          <line
            style={{ fill: 'none', stroke: '#fff', strokeWidth: 2 }}
            y2="23.333"
            transform="translate(12.083 0.417)"
          />
          <line
            style={{ fill: 'none', stroke: '#fff', strokeWidth: 2 }}
            x1="23.333"
            y1="0.275"
            transform="translate(0 12.488)"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default Layout2;
