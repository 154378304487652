import React from 'react';

import Layout2 from './../../components/icons/Layout2';
import Layout1 from './../../components/icons/Layout1';

import { TabHeader } from './tabs/TabHeader';
import { TimeLeft } from './topControls/TimeLeft';

import ArrowBack from '@material-ui/icons/ArrowBack';

import {
  AimIconAndTextButton,
  theme,
  styled,
  AimIconButton,
} from '@aim/components';

const LeftTopControlsContainer = styled('div')({
  display: 'flex',
});

const CenterTopControlsContainer = styled('div')({
  display: 'flex',
  margin: 0,
  position: 'absolute',
  top: 0,
  left: '50%',
  transform: 'translateX(-50%)',
});

const RightTopControlsContainer = styled('div')({
  display: 'flex',
  marginRight: 15,
});

const StyledButton = styled(AimIconAndTextButton)({
  '& span': {
    '& div': {
      color: theme.colors.greyScale.white,
    },
  },
});

const TopControlsComponent = ({
  title,
  navigateBack,
  selectedLayout,
  setSelectedLayout,
  onClickTabHeader,
  tabs,
  selectedTab,
  topControlHeight,
  end,
}) => {
  const ControlContainer = styled('div')({
    color: theme.colors.greyScale.white,
    backgroundColor: '#36393F',
    width: '100%',
    height: topControlHeight,
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: 1201,
  });

  return (
    <ControlContainer>
      <LeftTopControlsContainer>
        <StyledButton variant="none" text={title || ''} onClick={navigateBack}>
          <ArrowBack
            fontSize={'large'}
            htmlColor={theme.colors.greyScale.white}
          />
        </StyledButton>
      </LeftTopControlsContainer>

      <CenterTopControlsContainer>
        <AimIconButton
          variant={selectedLayout === 1 ? 'yellow' : 'none'}
          onClick={() => setSelectedLayout(1)}
        >
          <Layout1
            fontSize={'large'}
            htmlColor={theme.colors.greyScale.white}
          />
        </AimIconButton>
        <AimIconButton
          variant={selectedLayout === 2 ? 'yellow' : 'none'}
          onClick={() => setSelectedLayout(2)}
        >
          <Layout2
            fontSize={'large'}
            htmlColor={theme.colors.greyScale.white}
          />
        </AimIconButton>
      </CenterTopControlsContainer>

      <RightTopControlsContainer>
        <TimeLeft end={end} />
        {tabs &&
          tabs.map(({ id, name }) => (
            <TabHeader
              key={id}
              name={name}
              isSelected={selectedTab?.id === id}
              onClickTabHeader={() => onClickTabHeader({ id, name })}
            />
          ))}
      </RightTopControlsContainer>
    </ControlContainer>
  );
};

const TopControls = React.memo(TopControlsComponent);
export { TopControls };
