import React, { useState } from 'react';

import InputAdornment from '@material-ui/core/InputAdornment';
import SendIcon from '@material-ui/icons/Send';
import EmojiEmotionsRoundedIcon from '@material-ui/icons/EmojiEmotionsRounded';

import { styled, theme, AimTextField } from '@aim/components';

import { extractParticipationUserInfo } from '../../../../utils/utility';

import EmojiPopper from './EmojiPopper';

const ChatAimTextField = styled(AimTextField)({
  backgroundColor: 'transparent',
  border: `1px solid rgba(255, 255, 255, 0.6)`,
  borderRadius: 24,
  '& .MuiInput-input': {
    color: theme.colors.greyScale.white,
  },
});

const MessageInputContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const formControlStyle = { width: 'calc(100%)' };

const MessageInput = ({
  i18n,
  publish,
  userAndParticipation,
  scrollChatToBottom,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);

  const handleEmojiClick = (e) => {
    setAnchorEl(e.currentTarget);
    setOpen((prev) => !prev);
  };

  const onPopperClickAway = () => setOpen(false);

  const onMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const onSendMessage = () => {
    if (message && message.trim()) {
      setError(false);
      publish(
        {
          type: 'chat',
          text: message,
          userInfo: extractParticipationUserInfo(userAndParticipation),
        },
        (res) => {
          setError(res.status.error);
          if (!res.status.error) {
            setMessage('');
            scrollChatToBottom();
          }
        }
      );
    }
  };

  const onAddEmoji = (e) => {
    const emoji = e.native;
    setMessage(`${message}${emoji}`);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSendMessage();
    }
  };

  return (
    <MessageInputContainer>
      <ChatAimTextField
        error={error}
        helperText={error ? i18n.error.label : null}
        formControlStyle={formControlStyle}
        value={message}
        onChange={onMessageChange}
        onKeyDown={handleKeyDown}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <EmojiEmotionsRoundedIcon
                htmlColor="#FFCA28"
                style={{
                  cursor: 'pointer',
                  fontSize: 30,
                  paddingBottom: 10,
                  paddingRight: 5,
                  boxSizing: 'revert',
                }}
                onClick={handleEmojiClick}
              />
            </InputAdornment>
          ),
        }}
      />
      <SendIcon
        htmlColor="rgba(255, 255, 255, 0.6)"
        style={{ cursor: 'pointer', fontSize: 30 }}
        onClick={onSendMessage}
      />
      {open && (
        <EmojiPopper
          {...{
            onPopperClickAway,
            open,
            anchorEl,
            onAddEmoji,
            i18n,
          }}
        />
      )}
    </MessageInputContainer>
  );
};

export default MessageInput;
