export default {
  breakoutRoom: (intl) => ({
    dialog: {
      kicked: {
        title: {
          label: intl.formatMessage({
            description: 'dialog kick title',
            defaultMessage: 'The host got you out of the room',
          }),
        },
        message: {
          label: intl.formatMessage({
            description: 'dialog kick message',
            defaultMessage:
              'The host has temporarily got you out of the room. In this modality, you are not allowed to interact with other participants in the room. Once you have left the room, you might still join again from the appointment in your Agenda.',
          }),
        },
      },
      unpublished: {
        title: {
          label: intl.formatMessage({
            description: 'dialog unpublished title',
            defaultMessage: 'You are now an auditor attendee',
          }),
        },
        message: {
          label: intl.formatMessage({
            description: 'dialog unpublished message',
            defaultMessage:
              'The host has assigned you the role of auditor- only. In this modality, you are allowed to attend the appointment as an auditor-only so screen and audio sharing features are temporarily de-activated. To re-enable these features, you need to leave the room and re-join from your Agenda.',
          }),
        },
        snackbar: {
          label: intl.formatMessage({
            description: 'snackbar unpublished text label',
            defaultMessage:
              'The host has assigned you the role of auditor- only. In this modality, you are allowed to attend the appointment as an auditor-only so screen and audio sharing features are temporarily de-activated. To re-enable these features, you need to leave the room and re-join from your Agenda.',
          }),
        },
      },
      timeExpired: {
        title: {
          label: intl.formatMessage({
            description: 'dialog time expired title',
            defaultMessage: 'Time is up!',
          }),
        },
        message: {
          label: intl.formatMessage({
            description: 'dialog time expired message',
            defaultMessage: 'The meeting time is now expired.',
          }),
        },
        snackbar: {
          minutesLeftInfo: {
            label: intl.formatMessage({
              description: 'minutes to go snackbar label',
              defaultMessage: '5 minutes to go!',
            }),
          },
        },
      },
      error: {
        title: {
          label: intl.formatMessage({
            description: 'dialog error title',
            defaultMessage: 'Error',
          }),
        },
        message: {
          label: intl.formatMessage({
            description: 'dialog error message',
            defaultMessage: 'Unespected error, try again',
          }),
        },
      },
      buttons: {
        ok: {
          label: intl.formatMessage({
            description: 'dialog close button',
            defaultMessage: 'OK',
          }),
        },
      },
    },
    tabHeaders: {
      info: {
        label: intl.formatMessage({
          description: 'tab header info',
          defaultMessage: 'Info',
        }),
      },
      participants: {
        label: intl.formatMessage({
          description: 'tab header participants',
          defaultMessage: 'Participants',
        }),
      },
      chat: {
        label: intl.formatMessage({
          description: 'tab header chat',
          defaultMessage: 'Chat',
        }),
      },
      settings: {
        label: intl.formatMessage({
          description: 'tab header settings',
          defaultMessage: 'Settings',
        }),
      },
    },
  }),
  tabs: (intl) => ({
    closeText: {
      label: intl.formatMessage({
        description: 'close panel text',
        defaultMessage: 'close panel',
      }),
    },
  }),
  tabSettings: (intl) => ({
    group: {
      refresh: {
        label: intl.formatMessage({
          description: 'refresh devices tooltip text',
          defaultMessage: 'Refresh devices',
        }),
      },
      microphone: {
        label: intl.formatMessage({
          description: 'tab group microphone',
          defaultMessage: 'Microphone',
        }),
      },
      camera: {
        label: intl.formatMessage({
          description: 'tab group camera',
          defaultMessage: 'Camera',
        }),
      },
      notifications: {
        label: intl.formatMessage({
          description: 'tab group notifications',
          defaultMessage: 'Notifications',
        }),
        playText: {
          label: intl.formatMessage({
            description: 'tab play sound text',
            defaultMessage: 'Play sound when someone joins/leaves',
          }),
        },
      },
    },
  }),
  tabParticipants: (intl) => ({
    page: {
      userActive: {
        label: intl.formatMessage({
          description: 'tab participant user active',
          defaultMessage: 'Active',
        }),
      },
      tooltips: {
        camera: {
          enable: {
            label: intl.formatMessage({
              description: 'enable camera tooltip text',
              defaultMessage: 'Enable Camera',
            }),
          },
          disable: {
            label: intl.formatMessage({
              description: 'disable camera tooltip text',
              defaultMessage: 'Disable Camera',
            }),
          },
        },
        microphone: {
          enable: {
            label: intl.formatMessage({
              description: 'enable microphone tooltip text',
              defaultMessage: 'Unmute',
            }),
          },
          disable: {
            label: intl.formatMessage({
              description: 'disable microphone tooltip text',
              defaultMessage: 'Mute',
            }),
          },
        },
        videoSharing: {
          enable: {
            label: intl.formatMessage({
              description: 'enable video sharing tooltip text',
              defaultMessage: 'Share Screen',
            }),
          },
          disable: {
            label: intl.formatMessage({
              description: 'disable video sharing tooltip text',
              defaultMessage: 'Stop Sharing',
            }),
          },
        },
        kick: {
          label: intl.formatMessage({
            description: 'kick user tooltip text',
            defaultMessage: 'Kick User',
          }),
        },
        unpublish: {
          label: intl.formatMessage({
            description: 'unpublish user tooltip text',
            defaultMessage: 'Unpublish User',
          }),
        },
      },
    },
  }),
  tabInfo: (intl) => ({
    page: {
      hostedBy: {
        label: intl.formatMessage({
          description: 'tab info hosted by',
          defaultMessage: 'Hosted by',
        }),
      },
    },
  }),
  publisherDraggableControls: (intl) => ({
    you: {
      label: intl.formatMessage({
        description: 'publisher draggable controls you',
        defaultMessage: 'You',
      }),
    },
    presenting: {
      label: intl.formatMessage({
        description: 'publisher draggable presenting',
        defaultMessage: 'You are presenting to all',
      }),
    },
    tooltips: {
      camera: {
        enable: {
          label: intl.formatMessage({
            description: 'enable camera tooltip text',
            defaultMessage: 'Enable Camera',
          }),
        },
        disable: {
          label: intl.formatMessage({
            description: 'disable camera tooltip text',
            defaultMessage: 'Disable Camera',
          }),
        },
      },
      microphone: {
        enable: {
          label: intl.formatMessage({
            description: 'enable microphone tooltip text',
            defaultMessage: 'Unmute',
          }),
        },
        disable: {
          label: intl.formatMessage({
            description: 'disable microphone tooltip text',
            defaultMessage: 'Mute',
          }),
        },
      },
      videoSharing: {
        enable: {
          label: intl.formatMessage({
            description: 'enable video sharing tooltip text',
            defaultMessage: 'Share Screen',
          }),
        },
        disable: {
          label: intl.formatMessage({
            description: 'disable video sharing tooltip text',
            defaultMessage: 'Stop Sharing',
          }),
        },
        someoneSharing: {
          label: intl.formatMessage({
            description: 'someone sharing tooltip text',
            defaultMessage:
              'You cannot share as long as another user shares the screen',
          }),
        },
      },
      kick: {
        label: intl.formatMessage({
          description: 'kick user tooltip text',
          defaultMessage: 'Kick User',
        }),
      },
      unpublish: {
        label: intl.formatMessage({
          description: 'unpublish user tooltip text',
          defaultMessage: 'Unpublish User',
        }),
      },
    },
  }),
  social: (intl) => ({
    serviceMessages: {
      brJoin: {
        label: intl.formatMessage({
          description: 'br chat join',
          defaultMessage: 'joined the breakout room',
        }),
      },
      brLeave: {
        label: intl.formatMessage({
          description: 'br chat leave',
          defaultMessage: 'left the breakout room',
        }),
      },
    },
    messageInput: {
      emojiPicker: {
        title: intl.formatMessage({
          description: 'emoji picker title',
          defaultMessage: 'Pick your emoji...',
        }),
        i18n: {
          search: intl.formatMessage({
            description: 'emoji picker search',
            defaultMessage: 'Search',
          }),
          clear: intl.formatMessage({
            description: 'emoji picker clear',
            defaultMessage: 'Clear',
          }),
          notfound: intl.formatMessage({
            description: 'emoji picker not found',
            defaultMessage: 'No Emoji Found',
          }),
          skintext: intl.formatMessage({
            description: 'emoji picker skin text',
            defaultMessage: 'Choose your default skin tone',
          }),
          categories: {
            search: intl.formatMessage({
              description: 'emoji picker categories search',
              defaultMessage: 'Search Results',
            }),
            recent: intl.formatMessage({
              description: 'emoji picker categories recent',
              defaultMessage: 'Frequently Used',
            }),
            smileys: intl.formatMessage({
              description: 'emoji picker categories smileys',
              defaultMessage: 'Smileys & Emotion',
            }),
            people: intl.formatMessage({
              description: 'emoji picker categories people',
              defaultMessage: 'People & Body',
            }),
            nature: intl.formatMessage({
              description: 'emoji picker categories nature',
              defaultMessage: 'Animals & Nature',
            }),
            foods: intl.formatMessage({
              description: 'emoji picker categories foods',
              defaultMessage: 'Food & Drink',
            }),
            activity: intl.formatMessage({
              description: 'emoji picker categories activity',
              defaultMessage: 'Activity',
            }),
            places: intl.formatMessage({
              description: 'emoji picker categories places',
              defaultMessage: 'Travel & Places',
            }),
            objects: intl.formatMessage({
              description: 'emoji picker categories objects',
              defaultMessage: 'Objects',
            }),
            symbols: intl.formatMessage({
              description: 'emoji picker categories symbols',
              defaultMessage: 'Symbols',
            }),
            flags: intl.formatMessage({
              description: 'emoji picker categories flags',
              defaultMessage: 'Flags',
            }),
            custom: intl.formatMessage({
              description: 'emoji picker categories custom',
              defaultMessage: 'Custom',
            }),
          },
          categorieslabel: intl.formatMessage({
            description: 'emoji picker categories label',
            defaultMessage: 'Emoji categories',
          }),
          skintones: {
            1: intl.formatMessage({
              description: 'emoji picker default skin tone',
              defaultMessage: 'Default Skin Tone',
            }),
            2: intl.formatMessage({
              description: 'emoji picker light skin tone',
              defaultMessage: 'Light Skin Tone',
            }),
            3: intl.formatMessage({
              description: 'emoji picker medium-light skin tone',
              defaultMessage: 'Medium-Light Skin Tone',
            }),
            4: intl.formatMessage({
              description: 'emoji picker medium skin tone',
              defaultMessage: 'Medium Skin Tone',
            }),
            5: intl.formatMessage({
              description: 'emoji picker medium-dark skin tone',
              defaultMessage: 'Medium-Dark Skin Tone',
            }),
            6: intl.formatMessage({
              description: 'emoji picker dark skin tone',
              defaultMessage: 'Dark Skin Tone',
            }),
          },
        },
      },
      error: {
        label: intl.formatMessage({
          description: 'chat message input error',
          defaultMessage: 'Error, try again',
        }),
      },
    },
  }),
  tabKicked: (intl) => ({
    page: {
      title: {
        label: intl.formatMessage({
          description: 'tab kicked title text',
          defaultMessage: 'Currently Disabled',
        }),
      },
      message: {
        label: intl.formatMessage({
          description: 'tab kicked title text',
          defaultMessage:
            'Currently disabled. The host got you out of the room. In this modality, you are not allowed to interact with other participants in the room. Once you have left the room, you might still join again from the appointment saved in your Agenda.',
        }),
      },
    },
  }),
  tabTimeOut: (intl) => ({
    page: {
      title: {
        label: intl.formatMessage({
          description: 'tab timeout title text',
          defaultMessage: 'Time is up',
        }),
      },
    },
  }),
};
