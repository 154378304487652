import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

let WebcamVideoDisabled = (props) => (
  <SvgIcon
    {...props}
    width="16.095"
    height="16.095"
    viewBox="0 0 16.095 16.095"
    style={{
      fill: '#fff',
      fontSize: 40,
      filter: 'drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7))',
      ...props.style,
    }}
  >
    <g transform="translate(0 0)">
      <path
        d="M149.706,85.536a.5.5,0,0,1,.5.5v1.61a.5.5,0,0,0,.762.431l2.759-1.655v6.27l-2.759-1.655a.5.5,0,0,0-.231-.065l2.749,2.749.485.291a.5.5,0,0,0,.762-.431V85.536a.5.5,0,0,0-.762-.431l-2.759,1.655v-.721a1.511,1.511,0,0,0-1.509-1.509H144.3l1.006,1.006Z"
        transform="translate(-139.144 -81.512)"
      />
      <path
        d="M16.095,15.384.711,0,0,.711,2.307,3.018h-.8A1.511,1.511,0,0,0,0,4.527v7.041a1.511,1.511,0,0,0,1.509,1.509h9.053a1.5,1.5,0,0,0,1.2-.605l3.623,3.623Zm-5.533-3.312H1.509a.5.5,0,0,1-.5-.5V4.527a.5.5,0,0,1,.5-.5h1.8l7.718,7.718A.5.5,0,0,1,10.562,12.071Z"
        transform="translate(0 0)"
      />
    </g>
  </SvgIcon>
);

export default WebcamVideoDisabled;
