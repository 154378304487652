import React, { useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';

import {
  createGenerateClassName,
  createMuiTheme,
  StylesProvider,
  ThemeProvider,
} from '@material-ui/core/styles';

import { appState } from '@aim/common';
import { MuiTheme } from '@aim/components';
import * as Langs from './../compiled-lang';

import App from './App';

const theme = createMuiTheme(MuiTheme);

const generateClassName = createGenerateClassName({
  seed: 'aim-breakoutroom',
});

const BreakoutroomRootComponent = () => {
  const [locale, setLocale] = useState('en');

  useEffect(() => {
    const localeSubscription = appState.locale.subscribe((locale) => {
      setLocale(locale);
    });

    return () => {
      localeSubscription.unsubscribe();
    };
  }, []);

  return (
    <IntlProvider locale={locale} messages={Langs[locale]}>
      <StylesProvider generateClassName={generateClassName}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </StylesProvider>
    </IntlProvider>
  );
};

export default BreakoutroomRootComponent;
