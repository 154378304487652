import ding from './../sounds/ding.mp3';

export const playJoinNotification = () =>
  new Promise((resolve) => {
    var audio = new Audio(ding);
    const promise = audio.play();
    if (promise !== undefined) {
      promise
        .then(() => {
          resolve();
        })
        .catch(() => {
          resolve();
        });
    }
  });

export const minTwoDigits = (n) => {
  return (n < 10 ? '0' : '') + n;
};

export const extractParticipationUserInfo = (userAndParticipation) => {
  return {
    id: userAndParticipation.participation.id,
    user: {
      givenName: userAndParticipation?.givenName,
      familyName: userAndParticipation?.familyName,
      image: userAndParticipation?.participation?.profileImage,
    },
  };
};
