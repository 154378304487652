import React, { useEffect, useRef, useState, useCallback } from 'react';

import { styled } from '@aim/components';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const Parent = styled('div')({
  width: '100%',
  overflow: 'hidden',
  paddingTop: 5,
  paddingBottom: 5,
});

const SliderContainer = styled('div')({
  overflowX: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 'auto',
  scrollBehavior: 'smooth',
  width: 'calc(100% - 6rem - 40px)',
  height: '100%',
});

const SliderWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '100%',
});

const Button = styled('div')({
  position: 'absolute',
  padding: 5,
  cursor: 'pointer',
  width: 20,
  height: 20,
  textAlign: 'center',
  color: 'white',
  borderRadius: '50%',
});

const PrevButton = styled(Button)({
  left: '1rem',
});

const NextButton = styled(Button)({
  right: '1rem',
});

const buttonDisabledStyle = {
  opacity: 0.5,
  pointerEvents: 'none',
};

const AimCustomSlider = ({ carouselDivName, onNavigate, children }) => {
  //States
  const [prevDisable, setPrevDisable] = useState(false);
  const [nextDisable, setNextDisable] = useState(false);
  const [scrollTimeout, setScrollTimeout] = useState();

  //Refs
  const containerRef = useRef();

  // Callbacks
  const handleScroll = useCallback(() => {
    clearTimeout(scrollTimeout);
    setScrollTimeout(
      setTimeout(() => {
        onNavigate?.();
        checkButtons?.();
      }, 100)
    );
  }, [scrollTimeout, setScrollTimeout, onNavigate, checkButtons]);

  // Effects
  useEffect(() => {
    if (containerRef?.current) {
      containerRef?.current.addEventListener('scroll', handleScroll);

      return () => {
        containerRef?.current.removeEventListener('scroll', handleScroll);
      };
    }
  }, [handleScroll]);

  useEffect(() => {
    checkButtons();
  }, []);

  //Functions
  const checkButtons = () => {
    const nextPrevDisable =
      containerRef?.current?.scrollLeft <= 0 ? true : false;
    const nextNextDisable =
      containerRef?.current?.scrollLeft &&
      containerRef?.current?.offsetWidth &&
      containerRef?.current?.scrollWidth &&
      Math.round(containerRef?.current?.scrollLeft) +
        containerRef?.current?.offsetWidth >=
        containerRef?.current?.scrollWidth
        ? true
        : false;

    setPrevDisable(nextPrevDisable);
    setNextDisable(nextNextDisable);
  };

  return (
    <Parent>
      <SliderContainer ref={containerRef}>
        <SliderWrapper id={carouselDivName}>{children}</SliderWrapper>
        <PrevButton
          style={prevDisable ? { ...buttonDisabledStyle } : null}
          disabled={prevDisable}
          onClick={() => {
            if (containerRef?.current) {
              containerRef.current.scrollLeft -=
                containerRef?.current?.offsetWidth / 2;
            }
          }}
        >
          <ArrowBackIosIcon />
        </PrevButton>
        <NextButton
          style={nextDisable ? { ...buttonDisabledStyle } : null}
          disabled={nextDisable}
          onClick={() => {
            if (containerRef?.current) {
              containerRef.current.scrollLeft +=
                containerRef?.current?.offsetWidth / 2;
            }
          }}
        >
          <ArrowForwardIosIcon />
        </NextButton>
      </SliderContainer>
    </Parent>
  );
};

export { AimCustomSlider };
