import React from 'react';

import Avatar from '@material-ui/core/Avatar';

import { BreakoutRoomIcon } from '../../../components/BreakoutRoomIcon';

import { AimTypography, styled } from '@aim/components';

import WebcamVideoDisabled from '../../../components/icons/WebcamVideoDisabled';
import WebcamVideo from '../../../components/icons/WebcamVideo';
import Mute from '../../../components/icons/Mute';
import Microphone from '../../../components/icons/Microphone';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import Tooltip from '@material-ui/core/Tooltip';

const FlexContainer = styled('div')({
  display: 'flex',
});

const NameTypography = styled(AimTypography)({
  font: 'normal normal bold 16px/19px Roboto',
  letterSpacing: 0,
  color: '#FFFFFF',
});

const CompanyTypography = styled(AimTypography)({
  font: 'normal normal bold 12px/14px Roboto',
  letterSpacing: 0,
  color: '#84819A',
});

const ParticipantCardRow = ({
  name,
  company,
  image,
  isVideoEnabled,
  isMicrophoneOpen,
  sendMicrophoneSignal,
  sendCameraSignal,
  onKick,
  onUnpublish,
  i18n,
}) => {
  return (
    <div>
      <FlexContainer
        style={{
          justifyContent: 'space-between',
          paddingLeft: 10,
          paddingRight: 10,
          paddingBottom: 10,
        }}
      >
        <div style={{ width: '15%' }}>
          <Avatar alt={name} src={image}>
            {name[0]}
          </Avatar>
        </div>
        <div style={{ width: '55%' }}>
          <FlexContainer
            style={{
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'center',
            }}
          >
            <NameTypography margin={0} style={{ color: 'white' }}>
              {name}
            </NameTypography>
            <CompanyTypography margin={0}>{company}</CompanyTypography>
          </FlexContainer>
        </div>
        <div
          style={{ width: '35%', display: 'flex', justifyContent: 'flex-end' }}
        >
          {/* Kick user icon */}
          {onKick ? (
            <Tooltip title={i18n?.page.tooltips.kick.label}>
              <BreakoutRoomIcon small="true" variant={'BRred'} onClick={onKick}>
                <ExitToAppIcon style={{ fontSize: '1rem' }} />
              </BreakoutRoomIcon>
            </Tooltip>
          ) : null}

          {/* Kick user icon */}
          {onUnpublish ? (
            <Tooltip title={i18n?.page.tooltips.unpublish.label}>
              <BreakoutRoomIcon
                small="true"
                variant={'BRred'}
                onClick={onUnpublish}
              >
                <CancelPresentationIcon style={{ fontSize: '1rem' }} />
              </BreakoutRoomIcon>
            </Tooltip>
          ) : null}

          {/* Video enabled icon */}
          <Tooltip
            title={
              isVideoEnabled
                ? i18n?.page.tooltips.camera.disable.label
                : i18n?.page.tooltips.camera.enable.label
            }
          >
            <BreakoutRoomIcon
              small="true"
              variant={isVideoEnabled ? 'BRred' : 'BRgray'}
              style={{ pointerEvents: sendCameraSignal ? 'auto' : 'none' }}
              onClick={sendCameraSignal}
            >
              {isVideoEnabled ? (
                <WebcamVideo style={{ fontSize: '1rem' }} />
              ) : (
                <WebcamVideoDisabled style={{ fontSize: '1rem' }} />
              )}
            </BreakoutRoomIcon>
          </Tooltip>

          {/* Mute microphone icon*/}
          <Tooltip
            title={
              isMicrophoneOpen
                ? i18n?.page.tooltips.microphone.disable.label
                : i18n?.page.tooltips.microphone.enable.label
            }
          >
            <BreakoutRoomIcon
              small="true"
              variant={isMicrophoneOpen ? 'BRred' : 'BRgray'}
              style={{ pointerEvents: sendMicrophoneSignal ? 'auto' : 'none' }}
              onClick={sendMicrophoneSignal}
            >
              {isMicrophoneOpen ? (
                <Microphone style={{ fontSize: '1rem' }} />
              ) : (
                <Mute style={{ fontSize: '1rem' }} />
              )}
            </BreakoutRoomIcon>
          </Tooltip>
        </div>
      </FlexContainer>
    </div>
  );
};
export { ParticipantCardRow };
