import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const getParticipantByCognitoIdEventId = /* GraphQL */ `
  query ParticipantByCognitoIdEventId(
    $cognitoUserId: ID
    $participationEventId: ModelIDKeyConditionInput
  ) {
    participantByCognitoIdEventId(
      cognitoUserId: $cognitoUserId
      participationEventId: $participationEventId
    ) {
      items {
        id
        createdAt
        cluster
        event {
          id
          name
        }
        cognitoUserId
        givenName
        familyName
        email
        userShowcase {
          id
          profileImage {
            id
            extension
            originalName
            size
          }
        }
      }
    }
  }
`;

export const useParticipations = () => {
  const getByCognitoIdEventId = (userId, eventId, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: getParticipantByCognitoIdEventId,
        variables: {
          cognitoUserId: userId,
          participationEventId: { eq: eventId },
        },
      })
        .then((response) => {
          const participation =
            response.data?.participantByCognitoIdEventId?.items?.[0];
          resolve(participation);
        })
        .catch((e) => {
          console.error(
            'get-participation-by-cognito-id-event-id-breakoutRoom',
            e
          );
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const getMyEventParticipation = async (id, eventId) => {
    const res = await getByCognitoIdEventId(id, eventId);

    if (res) {
      const user = {
        id: id,
        email: res.email,
        givenName: res.givenName,
        familyName: res.familyName,
        businessName: res.businessName,
        participation: res,
      };
      return user;
    }
  };
  return { getMyEventParticipation, getByCognitoIdEventId };
};
