/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';

import { useIntl } from 'react-intl';
import translation from '../../../utils/translation';

import { useParams } from 'react-router-dom';

import { format, parseISO } from 'date-fns';

import Avatar from '@material-ui/core/Avatar';

import { CustomIntl, AimTypography, styled } from '@aim/components';

import { aws } from '@aim/common';

const MainContainer = styled('div')({
  overflowY: 'auto',
  overflowX: 'hidden',
  height: 'calc(100vh - 140px)',
  paddingTop: 20,
});

const InfoContainer = styled('div')({
  border: '1px solid #707070',
  borderRadius: 3,
  margin: 20,
  textAlign: 'center',
  padding: 20,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
});

const TabInfoComponent = ({ brInfo }) => {
  // Hooks
  const { eventId, sponsorId } = useParams();
  const intl = CustomIntl(useIntl());
  const i18n = translation.tabInfo(intl);

  // States
  const [logo, setLogo] = useState();

  // Effects
  useEffect(() => {
    if (brInfo) getLogo();
  }, [brInfo]);

  // Functions
  const getLogo = async () => {
    const s3FolderProfileImage = `events/${eventId}/sponsor/frontoffice/${sponsorId}/profileImage/`;

    const logoImage = brInfo?.logo?.id
      ? await aws.Storage.get(
          `${s3FolderProfileImage}${brInfo?.logo?.id}${brInfo?.logo?.extension}`
        )
      : null;

    setLogo(logoImage);
  };

  const start = brInfo?.start
    ? format(parseISO(brInfo.start), 'LLLL, do y HH:mm')
    : '';
  const end = brInfo?.end ? format(parseISO(brInfo.end), 'HH:mm') : '';

  return (
    <MainContainer>
      <InfoContainer>
        {brInfo?.title && (
          <AimTypography variant="h4Regular">{brInfo.title}</AimTypography>
        )}
        <Avatar
          src={logo}
          alt={brInfo?.sponsor || 'avatar'}
          style={{ width: 100, height: 100, margin: brInfo?.title ? 15 : 0 }}
        >
          {brInfo?.sponsor?.[0]}
        </Avatar>
        <AimTypography variant="text" margin={0}>
          {`${start}-${end}`}
        </AimTypography>
        <AimTypography variant="text" margin={0}>
          {`${i18n.page.hostedBy.label}: ${brInfo?.sponsor}`}
        </AimTypography>
      </InfoContainer>
    </MainContainer>
  );
};

const TabInfo = React.memo(TabInfoComponent);
export { TabInfo };
