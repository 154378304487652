import React from 'react';

import { useIntl } from 'react-intl';

import translation from './../../../utils/translation';

import { ParticipantCardRow } from './ParticipantCardRow';
import { AimTypography, CustomIntl, styled } from '@aim/components';

const MainContainer = styled('div')({
  overflowY: 'auto',
  overflowX: 'hidden',
  height: 'calc(100vh - (120px + 20px))',
});

const TabParticipants = ({
  publisherData,
  isMicrophoneOpen,
  unpublishUser,
  handleMute,
  isVideoEnabled,
  toggleVideo,
  subscribers,
  sendSignal,
  isModerator,
  kickUser,
  participantsMapImage,
  userAndParticipation,
}) => {
  //Hooks
  const intl = CustomIntl(useIntl());
  const i18n = translation.tabParticipants(intl);

  return (
    <MainContainer>
      <div
        style={{
          marginLeft: 10,
          marginBottom: 16,
        }}
      >
        <AimTypography
          margin={0}
          style={{
            fontSize: '1rem',
            fontFamily: 'Roboto',
          }}
        >
          {i18n.page.userActive.label} ({(subscribers?.length || 0) + 1})
        </AimTypography>
      </div>

      {publisherData ? (
        <ParticipantCardRow
          name={`${publisherData?.givenName} ${publisherData?.familyName}`}
          company={''} //TODO
          image={userAndParticipation?.participation?.profileImage}
          isMicrophoneOpen={isMicrophoneOpen}
          isVideoEnabled={isVideoEnabled}
          sendMicrophoneSignal={handleMute}
          sendCameraSignal={toggleVideo}
          i18n={i18n}
        />
      ) : null}
      {subscribers?.map((s, idx) => {
        return (
          <ParticipantCardRow
            key={idx}
            company={''} //TODO
            name={s?.stream?.name}
            image={
              participantsMapImage.find((i) => i.streamId === s?.stream?.id)
                ?.image
            }
            isMicrophoneOpen={s?.stream?.hasAudio}
            isVideoEnabled={s?.stream?.hasVideo}
            onKick={isModerator && kickUser ? () => kickUser(s) : null}
            onUnpublish={
              isModerator && unpublishUser ? () => unpublishUser(s) : null
            }
            sendMicrophoneSignal={
              isModerator && sendSignal
                ? () => sendSignal(s, 'microphone')
                : null
            }
            sendCameraSignal={
              isModerator && sendSignal ? () => sendSignal(s, 'camera') : null
            }
            i18n={i18n}
          />
        );
      })}
    </MainContainer>
  );
};

export { TabParticipants };
