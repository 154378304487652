import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

let WebcamVideo = (props) => (
  <SvgIcon
    {...props}
    width="16.969"
    height="11.1"
    viewBox="0 0 16.969 11.1"
    style={{
      fill: '#fff',
      fontSize: 40,
      filter: 'drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7))',
      ...props.style,
    }}
  >
    <path
      d="M1944.923,2179.631a.56.56,0,0,0-.289.08l-3.019,1.811v-.459a2.053,2.053,0,0,0-2.05-2.049h-9a2.053,2.053,0,0,0-2.049,2.049v7a2.053,2.053,0,0,0,2.049,2.05h9a2.046,2.046,0,0,0,1.928-1.379.618.618,0,0,0,.027-.086,2.559,2.559,0,0,0,.03-.832v-.043l3.084,1.88a.56.56,0,0,0,.77-.192.554.554,0,0,0,.08-.289v-8.98A.561.561,0,0,0,1944.923,2179.631Zm-.561,8.549-3.079-1.847c-.005,0-.012,0-.018-.005a.534.534,0,0,0-.265-.078.55.55,0,0,0-.55.55v1.586a.945.945,0,0,1-.885.628h-9a.952.952,0,0,1-.95-.951v-7a.952.952,0,0,1,.95-.95h9a.952.952,0,0,1,.951.95v1.5a.549.549,0,0,0,.549.549c.17,0,3.3-1.929,3.3-1.929Z"
      transform="translate(-1928.516 -2179.014)"
    />
  </SvgIcon>
);

export default WebcamVideo;
