/* eslint-disable react/display-name */
import React, { useState, useEffect, useReducer } from 'react';

import { useParams, useHistory } from 'react-router-dom';
import PubNub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';
import { useIntl } from 'react-intl';

import { differenceInSeconds } from 'date-fns';

import Drawer from '@material-ui/core/Drawer';

import { useBreakoutRoom } from './../utils/useBreakoutRoom';
import { playJoinNotification } from './../utils/utility';

import { PublisherDraggableControls } from './breakoutroom/PublisherDraggableControls';
import { TopControls } from './breakoutroom/TopControls';
import { Tabs } from './breakoutroom/Tabs';
import { useTabs } from './breakoutroom/tabs/useTabs';

import CloseIcon from '@material-ui/icons/Close';

import {
  AimDialog,
  AimIconAndTextButton,
  CustomIntl,
  AimTypography,
  AimSnackbar,
  AimSnackbarSeverity,
  styled,
  theme,
} from '@aim/components';

import { fileHelper, appState } from '@aim/common';

import translation from './../utils/translation';

import { AimCustomSlider } from './AimCustomSlider';

function subscribersMapImageReducer(state, action) {
  const nextSubscribersImage = [...state];

  nextSubscribersImage.push(action);
  return nextSubscribersImage;
}

const StyledDrawer = styled(Drawer)({
  '& .MuiPaper-root': {
    borderLeft: 'none',
  },
});

const getSecondsFromServerTime = (date, serverTime) => {
  return differenceInSeconds(date, serverTime);
};

const BreakoutroomPage = () => {
  //Constants
  const subscribersDivName = 'subscribers';
  const screenSharingDivName = 'screens';
  const publisherDivName = 'publisher';
  const carouselDivName = 'carousel';
  const rightColumnWidth = 470;
  const topControlHeight = 55;

  // Intl Hooks
  const intl = CustomIntl(useIntl());
  const i18n = translation.breakoutRoom(intl);
  const tabs = useTabs(i18n.tabHeaders);

  //State
  const [publisherData, setPublisherData] = useState();
  const [user, setUser] = useState();
  const [isModerator, setIsModerator] = useState(false);
  const [isSoundNotificationActive, setSoundNotificationActive] = useState(
    localStorage.getItem('aimBRPlayNotification') === 'true' || false
  );
  const [client, setClient] = useState();
  const [clientData, setClientData] = useState({
    uuid: null,
    msgChannel: null,
  });
  const [participantsMapImage, dispatchParticipantsMapImage] = useReducer(
    subscribersMapImageReducer,
    []
  );
  const [dialog, setDialog] = useState({ open: false });
  const [displayControls, setDisplayControls] = useState(true);
  const [selectedLayout, setSelectedLayout] = useState(2);
  const [selectedTab, setSelectedTab] = useState(null);
  const [layoutListChildStyle, setLayoutListChildStyle] = useState({
    'min-width': { 1: selectedTab ? '24%' : '16%', 2: '0px' },
    'max-width': { 1: selectedTab ? '24%' : '16%', 2: 'none' },
    'margin-right': { 1: '10px', 2: '0px' },
  });
  const [snackbar, setSnackbar] = useState({ isOpen: false });

  // Hooks
  const { eventId, breakoutroomId, sponsorId } = useParams();
  const history = useHistory();
  const {
    info,
    serverTime,
    isMicrophoneOpen,
    audioInputs,
    videoInputs,
    isScreenShared,
    publisherScreen,
    shareScreenFunction,
    handleMute,
    toggleVideo,
    handleInputAudio,
    handleInputVideo,
    isVideoEnabled,
    publisher,
    subscribers,
    sendSignal,
    hideSubscribersOutsideViewport,
    fetchDevices,
    currentAudioInput,
    currentVideoInput,
    screenSharedData,
  } = useBreakoutRoom({
    breakoutroomId,
    subscribersDivName,
    screenSharingDivName,
    publisherDivName,
    carouselDivName,
    eventId,
    handleError: (error, showError) => handleErrorDialog(error, showError),
    onParticipantJoin: () => handleParticipantJoin(),
    onKick: () => {
      setDialog({
        open: true,
        data: {
          message: i18n.dialog.kicked.message.label,
          title: i18n.dialog.kicked.title.label,
          kick: true,
          timeExpired: false,
        },
      });
      setDisplayControls(false);
    },
    onUnpublish: () => {
      showSnackbar({
        severity: AimSnackbarSeverity.error,
        message: i18n.dialog.unpublished.snackbar.label,
        style: {
          backgroundColor: theme.colors.light.red,
          color: theme.colors.secondary.red,
          display: 'flex',
          alignItems: 'center',
        },
        actions: () => {
          return (
            <div
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                margin: 10,
                cursor: 'pointer',
              }}
              onClick={() => {
                setSnackbar({
                  isOpen: false,
                  actions: null,
                  style: null,
                  autoHideDuration: null,
                  onClose: () => {},
                });
              }}
            >
              <CloseIcon htmlColor={'black'} />
            </div>
          );
        },
        onClose: () => {},
      });
      setDisplayControls(false);
    },
    selectedLayout,
    layoutListChildStyle,
  });

  //Effects
  useEffect(() => {
    setLayoutListChildStyle({
      'min-width': { 1: selectedTab ? '24%' : '16%', 2: '0px' },
      'max-width': { 1: selectedTab ? '24%' : '16%', 2: 'none' },
      'margin-right': { 1: '10px', 2: '0px' },
    });
  }, [selectedTab]);

  useEffect(() => {
    if (publisher) {
      const stringData = publisher?.session?.connection?.data;
      const nextPublisherData = JSON.parse(stringData);
      const nextIsModerator = publisher?.session?.capabilities?.permittedTo(
        'forceDisconnect'
      );
      setIsModerator(nextIsModerator);
      setPublisherData(nextPublisherData);
    }
  }, [publisher]);

  useEffect(() => {
    const subscription = appState.user.subscribe(async (value) => {
      const nextUser = { ...value };
      const participation = nextUser.userAndParticipation.participation;

      const profileImage = participation?.userShowcase?.profileImage;

      const image = await getUserShowCaseImage(
        participation?.id,
        profileImage,
        eventId
      );

      participation.profileImage = image;

      setUser(nextUser);
    });
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (user && breakoutroomId) {
      initChatClient();
    }
  }, [user, breakoutroomId]);

  useEffect(() => {
    const nextParticipantsMapImage = [...participantsMapImage];

    subscribers.forEach(async (s) => {
      const data = s?.stream?.connection?.data
        ? JSON.parse(s?.stream?.connection?.data)
        : null;

      const profileImage = data
        ? data?.participation?.userShowcase?.profileImage
        : null;

      const participationId = data ? data?.participation?.id : null;

      const streamId = s?.stream?.id;

      const isAlreadyPresent = nextParticipantsMapImage.find(
        (i) => i.streamId === streamId
      );
      if (!isAlreadyPresent) {
        const image = await getUserShowCaseImage(
          participationId,
          profileImage,
          eventId
        );

        dispatchParticipantsMapImage({ streamId, image });
      }
    });
  }, [subscribers]);

  useEffect(() => {
    if (info?.end && serverTime && onTimeExpired) {
      let secondsToEnd = getSecondsFromServerTime(
        new Date(info?.end),
        new Date(serverTime)
      );
      secondsToEnd =
        secondsToEnd > 2147483647 / 1000 ? 2147483647 / 1000 : secondsToEnd;

      const timeouts = [];

      if (secondsToEnd - 60 * 5 > 0) {
        // 5 minute before the breakout room end
        const timeout10MinuteBefore = setTimeout(() => {
          showSnackbar({
            severity: AimSnackbarSeverity.error,
            message: i18n.dialog.timeExpired.snackbar.minutesLeftInfo.label,
            style: {
              backgroundColor: '#C7F2F2',
              color: '#30CFD0',
              display: 'flex',
              alignItems: 'center',
            },
            autoHideDuration: 5 * 1000,
            actions: () => <></>,
            onClose: () => {
              setSnackbar({
                isOpen: false,
                actions: null,
                style: null,
                autoHideDuration: null,
                onClose: () => {},
              });
            },
          });
        }, (secondsToEnd - 60 * 5) * 1000);
        timeouts.push(timeout10MinuteBefore);
      }
      if (secondsToEnd > 0) {
        // the end of the breakout room time
        const timeoutEnd = setTimeout(() => {
          onTimeExpired();
        }, secondsToEnd * 1000);
        timeouts.push(timeoutEnd);
      }
      /* if (secondsToEnd + 60 * 10 > 0) {
        // time after the end of breakout room
        const timeout10MinuteAfter = setTimeout(() => {
          onTimeExpired();
        }, (secondsToEnd + 60 * 10) * 1000);
        timeouts.push(timeout10MinuteAfter);
      } */
      return () => {
        for (const timeout of timeouts) {
          clearTimeout(timeout);
        }
      };
    }
  }, [info, serverTime, onTimeExpired]);

  //Functions
  const initChatClient = async () => {
    if (clientData.uuid || client) return;

    const newClientData = {
      uuid: user.userAndParticipation.participation.id,
      msgChannel: breakoutroomId,
    };
    setClientData(newClientData);

    setClient(
      new PubNub({
        publishKey: process.env.PUBNUB_PUBLISH_KEY,
        subscribeKey: process.env.PUBNUB_SUBSCRIBE_KEY,
        uuid: newClientData.uuid,
      })
    );
  };

  const kickUser = (session) => {
    publisher.session.forceDisconnect(session.stream.connection);
  };

  const unpublishUser = (session) => {
    publisher.session.forceUnpublish(session.stream);
  };

  const setAudioInput = (value) => {
    handleInputAudio(value);
  };

  const setVideoInput = (value) => {
    handleInputVideo(value);
  };

  const handleSoundNotification = (value) => {
    setSoundNotificationActive(value);
    localStorage.setItem('aimBRPlayNotification', value);
  };

  const handleParticipantJoin = () => {
    if (localStorage.getItem('aimBRPlayNotification') === 'true')
      playJoinNotification();
  };

  const navigateBack = () => {
    history.push(
      `/events/${eventId}/sponsors/${sponsorId}/slots/${breakoutroomId}`
    );
  };

  const onClickTabHeader = (tab) => {
    setSelectedTab(tab);
  };

  const getSubscriberDivStyle = () => {
    const defaultObject = {
      display: isScreenShared ? 'none' : 'flex',
      flex: 1,
      maxWidth: '100%',
      maxHeight: `calc(${selectedLayout === 2 ? '(100% / 2) ' : '80%'} )`,
      flexWrap: 'wrap',
      justifyContent: 'center',
    };

    switch (subscribers.length) {
      case 1:
      case 2:
        return { ...defaultObject, maxHeight: '100%' };
      default:
        return defaultObject;
    }
  };

  const getUserShowCaseImage = async (
    participationId,
    profileImage,
    eventId
  ) => {
    return profileImage
      ? fileHelper.getPublicFileLink({
          dirPath: `events/${eventId}/user/${participationId}/showcase/profileImage/`,
          skipFileDataOnS3Link: true,
        })
      : null;
  };

  const handleErrorDialog = (error, openDialog) => {
    console.error(error);
    if (error && openDialog) {
      const data = {
        message: i18n.dialog.error.message.label,
        title: i18n.dialog.error.title.label,
        kick: false,
        timeExpired: false,
      };
      setDialog({ open: true, data });
    }
  };

  const handleDialogClose = (dialog) => {
    if (dialog?.data?.kick) {
      navigateBack();
    } else {
      setDialog({ open: false });
    }
  };

  const onTimeExpired = () => {
    setDialog({
      open: true,
      data: {
        message: i18n.dialog.timeExpired.message.label,
        title: i18n.dialog.timeExpired.title.label,
        kick: true,
        timeExpired: true,
      },
    });
    setDisplayControls(false);
  };

  const showSnackbar = (data) => {
    setSnackbar({
      isOpen: true,
      ...data,
    });
  };

  return (
    <>
      <TopControls
        title={`${info?.event} - ${info?.sponsor}`}
        navigateBack={navigateBack}
        selectedLayout={selectedLayout}
        setSelectedLayout={setSelectedLayout}
        onClickTabHeader={onClickTabHeader}
        tabs={tabs}
        selectedTab={selectedTab}
        topControlHeight={topControlHeight}
        end={info?.end}
      />
      <div
        style={{
          display: 'flex',
          backgroundColor: '#36393F',
          height: '100%',
        }}
      >
        <div
          style={{
            width: `calc(100vw - ${
              selectedTab ? rightColumnWidth + 'px' : '0px'
            })`,
          }}
        >
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent:
                selectedLayout === 2 ? 'flex-start' : 'space-between',
            }}
          >
            <div id={`${subscribersDivName}`} style={getSubscriberDivStyle()} />

            <div
              style={{
                display:
                  selectedLayout === 2 || isScreenShared ? 'none' : 'flex',
                height: '20%',
                position: 'relative',
                backgroundColor: '#04000073',
              }}
            >
              <AimCustomSlider
                carouselDivName={carouselDivName}
                onNavigate={hideSubscribersOutsideViewport}
              />
            </div>

            <div
              id={`${screenSharingDivName}`}
              style={{
                display: isScreenShared ? 'flex' : 'none',
                flex: 1,
                maxWidth: '100%',
                maxHeight: '100%',
                flexWrap: 'wrap',
              }}
            />
            {isScreenShared && screenSharedData ? (
              <div
                style={{
                  position: 'absolute',
                  backgroundColor: 'black',
                  cursor: 'context-menu',
                  opacity: 0.6,
                  left: 10,
                  bottom: 60,
                }}
              >
                <AimTypography style={{ color: 'white' }}>
                  {`${screenSharedData?.givenName} ${screenSharedData?.familyName} `}
                </AimTypography>
              </div>
            ) : null}
          </div>
        </div>
        <StyledDrawer variant="persistent" anchor="right" open={selectedTab}>
          <div
            style={{
              width: rightColumnWidth,
              backgroundColor: 'black',
              height: `calc(100% - ${topControlHeight}px)`,
              marginTop: topControlHeight,
            }}
          >
            {client && (
              <PubNubProvider client={client}>
                <Tabs
                  publisherData={publisherData}
                  isMicrophoneOpen={isMicrophoneOpen}
                  handleMute={handleMute}
                  isVideoEnabled={isVideoEnabled}
                  toggleVideo={toggleVideo}
                  subscribers={subscribers}
                  isModerator={isModerator}
                  sendSignal={sendSignal}
                  kickUser={kickUser}
                  unpublishUser={unpublishUser}
                  audioInputs={audioInputs}
                  videoInputs={videoInputs}
                  currentAudioInput={currentAudioInput}
                  currentVideoInput={currentVideoInput}
                  handleInputAudio={setAudioInput}
                  handleInputVideo={setVideoInput}
                  isSoundNotificationActive={isSoundNotificationActive}
                  handleSoundNotification={handleSoundNotification}
                  msgChannel={clientData?.msgChannel}
                  uuid={clientData?.uuid}
                  userAndParticipation={user.userAndParticipation}
                  session={breakoutroomId}
                  participantsMapImage={participantsMapImage}
                  displayControls={displayControls}
                  fetchDevices={fetchDevices}
                  selectedTab={selectedTab}
                  onClickTabHeader={onClickTabHeader}
                  brInfo={info}
                  isKicked={dialog?.data?.kick && !dialog?.data?.timeExpired}
                  isTimeOut={dialog?.data?.kick && dialog?.data?.timeExpired}
                />
              </PubNubProvider>
            )}
          </div>
        </StyledDrawer>
      </div>
      {displayControls ? (
        <PublisherDraggableControls
          {...{
            publisherDivName,
            handleMute,
            isMicrophoneOpen,
            toggleVideo,
            isVideoEnabled,
            isScreenShared,
            shareScreenFunction,
            publisherScreen,
          }}
        />
      ) : null}
      <AimDialog
        open={dialog?.open}
        title={dialog?.data?.title}
        onClose={() => {
          handleDialogClose(dialog);
        }}
        hideActions
        customActions={
          <div style={{ width: '100%' }}>
            <AimIconAndTextButton
              variant="greenFill"
              text={i18n.dialog.buttons.ok.label}
              style={{
                paddingLeft: 30,
                paddingRight: 30,
              }}
              onClick={() => {
                handleDialogClose(dialog);
              }}
            />
          </div>
        }
      >
        <AimTypography>{dialog?.data?.message}</AimTypography>
      </AimDialog>
      <AimSnackbar
        open={snackbar?.isOpen}
        onClose={() => {
          snackbar?.onClose?.();
        }}
        customStyle={snackbar?.style}
        severity={snackbar?.severity}
        alertActions={snackbar?.actions?.()}
        autoHideDuration={snackbar?.autoHideDuration}
      >
        <AimTypography
          margin={0}
          style={{
            fontSize: 12,
            color: theme.colors.greyScale.black,
            cursor: 'context-menu',
          }}
        >
          {snackbar.message}
        </AimTypography>
      </AimSnackbar>
    </>
  );
};

const Breakoutroom = React.memo(BreakoutroomPage);

export default Breakoutroom;
