const workercode = () => {
  var activity = {};

  self.onmessage = function (e) {
    var now = Date.now();
    if (e.data.audioLevel > 0.01) {
      if (!activity[e.data.subscriberId]) {
        activity[e.data.subscriberId] = { timestamp: now, talking: false };
      } else if (activity[e.data.subscriberId].talking) {
        activity[e.data.subscriberId].timestamp = now;
      } else if (now - activity[e.data.subscriberId].timestamp > 1000) {
        activity[e.data.subscriberId].talking = true;
        /* console.log(
          'start talking',
          e.data.subscriberId,
          activity[e.data.subscriberId]
        ); */

        self.postMessage({
          type: 'startTalking',
          subscriberId: e.data.subscriberId,
        });
      }
    } else if (
      activity[e.data.subscriberId] &&
      now - activity[e.data.subscriberId].timestamp > 3000
    ) {
      // detected low audio activity for more than 3s
      if (activity[e.data.subscriberId].talking) {
        /* console.log(
          'stop talking',
          e.data.subscriberId,
          activity[e.data.subscriberId]
        ); */

        self.postMessage({
          type: 'stopTalking',
          subscriberId: e.data.subscriberId,
        });
      }
      activity[e.data.subscriberId] = null;
    }
  };
};

let code = workercode.toString();
code = code.substring(code.indexOf('{') + 1, code.lastIndexOf('}'));

const blob = new Blob([code], { type: 'application/javascript' });
const worker_script = URL.createObjectURL(blob);

module.exports = worker_script;
