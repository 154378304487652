import React from 'react';

import { AimTypography, theme, styled } from '@aim/components';

const StyledTabHeader = styled('div')({
  color: theme.colors.greyScale.white,
  paddingRight: 25,
  cursor: 'pointer',
});

const StyledTypography = styled(AimTypography)(({ selected }) => ({
  font: 'normal normal normal 20px/34px Roboto',
  opacity: selected ? 1 : 0.58,
  fontWeight: '300 !important',
}));

export const TabHeader = ({ name, isSelected, onClickTabHeader }) => {
  return (
    <StyledTabHeader onClick={onClickTabHeader}>
      <StyledTypography selected={isSelected}>{name}</StyledTypography>
    </StyledTabHeader>
  );
};
