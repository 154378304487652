import React from 'react';

import { useIntl } from 'react-intl';

import { AimTypography, CustomIntl, styled } from '@aim/components';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import translation from './../../../utils/translation';

const MainContainer = styled('div')({
  overflowY: 'auto',
  overflowX: 'hidden',
  height: 'calc(100vh - 140px)',
  paddingTop: 20,
});

const ContentWrapper = styled('div')({
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
});

const TabTimeOut = () => {
  // Hooks
  const intl = CustomIntl(useIntl());
  const i18n = translation.tabTimeOut(intl);

  return (
    <MainContainer>
      <ContentWrapper>
        <InfoOutlinedIcon />
        <AimTypography
          style={{ font: 'normal normal normal 16px/21px Roboto' }}
        >
          {i18n.page.title.label}
        </AimTypography>
      </ContentWrapper>
    </MainContainer>
  );
};

export { TabTimeOut };
