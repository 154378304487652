import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

let Layout1 = (props) => (
  <SvgIcon
    {...props}
    width="41"
    height="41"
    viewBox="0 0 41 41"
    style={{
      transform: 'rotate(90deg)',
      filter: 'drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7))',
      ...props.style,
    }}
  >
    {/*  <defs>
		<style>.a{fill:rgba(4,0,0,0.45);}.b,.d{fill:none;}.b{stroke:#fff;stroke-width:2px;}.c{stroke:none;}</style>
	</defs> */}
    <g transform="translate(-803 -23)">
      <g transform="translate(811 31)">
        <g
          style={{ stroke: '#fff', strokeWidth: 2, fill: 'none' }}
          transform="translate(0 0)"
        >
          <rect style={{ stroke: 'none' }} width="25" height="25" />
          <rect style={{ fill: 'none' }} x="1" y="1" width="23" height="23" />
        </g>
        <line
          style={{ stroke: '#fff', strokeWidth: 2, fill: 'none' }}
          y2="23.218"
          transform="translate(12.573 0.532)"
        />
        <path
          style={{ stroke: '#fff', strokeWidth: 2, fill: 'none' }}
          d="M-5.045,0H6.21"
          transform="translate(6.363 12.803)"
        />
      </g>
    </g>
  </SvgIcon>
);

export default Layout1;
