import React, { useRef, useEffect } from 'react';

import { useChat, styled } from '@aim/components';
import { usePubNub } from 'pubnub-react';

import Message from './social/Message';
import ServiceMessage from './social/ServiceMessage';
import MessageInput from './social/MessageInput';

const MainContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100vh - (120px + 20px))',
  paddingTop: 40,
  // Handle scrollbar properties
  '& ::-webkit-scrollbar': {
    width: 6,
  },
  '& ::-webkit-scrollbar-track': {
    background: '#ffffff',
  },
  '& ::-webkit-scrollbar-thumb': {
    background: '#36393F',
    borderRadius: 5,
  },
  '& ::-webkit-scrollbar-thumb:hover': {
    background: 'rgba(149, 152, 154, 1)',
  },
});

const ChatContainer = styled('div')({
  paddingRight: 30,
  paddingLeft: 30,
  overflowY: 'auto',
});

const MessageInputContainer = styled('div')({
  paddingRight: 30,
  paddingLeft: 30,
});

const Filler = styled('div')({
  flexGrow: 1,
});

const isElementInViewport = (el) => {
  var rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

const TabSocial = ({
  i18n,
  msgChannel,
  uuid,
  userAndParticipation,
  displayControls,
  userMetadata,
}) => {
  // Refs
  const chatContainerRef = useRef();
  const hiddenDivTop = useRef();
  const hiddenDivBottom = useRef();

  const client = usePubNub();
  const { messages, publish } = useChat({
    client,
    userUuid: uuid,
    userMetadata,
    isOnline: true,
    presChannel: msgChannel,
    msgChannel,
    // onJoin: () => handleJoin(),
    // onLeave: () => handleLeave(),
  });

  // Effects
  useEffect(() => {
    // auto scroll chat
    if (hiddenDivTop.current && hiddenDivBottom.current) {
      const isHiddenDivTopInViewport = isElementInViewport(
        hiddenDivTop.current
      );
      const isHiddenDivBottomInViewport = isElementInViewport(
        hiddenDivBottom.current
      );
      (isHiddenDivTopInViewport || isHiddenDivBottomInViewport) &&
        scrollChatToBottom();
    }
  }, [messages]);

  // Functions
  const scrollChatToBottom = () => {
    chatContainerRef.current.scrollTop =
      chatContainerRef.current.scrollHeight -
      chatContainerRef.current.clientHeight;
  };

  const renderMessage = (m) => {
    switch (m?.message?.content?.type) {
      /* case 'sys':
        return (
          <ServiceMessage
            message={`${m?.message?.content?.userInfo?.user?.givenName} ${
              m?.message?.content?.userInfo?.user?.familyName
            } ${i18n.serviceMessages[m?.message?.content?.text]?.label}`}
          />
        ); */
      case 'chat':
        return (
          <Message
            message={m?.message?.content?.text}
            givenName={m?.message?.content?.userInfo?.user?.givenName}
            familyName={m?.message?.content?.userInfo?.user?.familyName}
            avatar={m?.message?.content?.userInfo?.user?.image}
            isMine={
              userAndParticipation?.participation?.id ===
              m?.message?.content?.userInfo?.id
            }
          />
        );
      default:
        break;
    }
  };

  return (
    <MainContainer>
      <ChatContainer ref={chatContainerRef}>
        {messages.map((m, idx) => {
          return (
            <React.Fragment key={m?.message?.id || idx}>
              {idx === messages.length - 1 && <div ref={hiddenDivTop} />}
              {renderMessage(m)}
              {idx === messages.length - 1 && <div ref={hiddenDivBottom} />}
            </React.Fragment>
          );
        })}
      </ChatContainer>
      <Filler />
      {displayControls ? (
        <MessageInputContainer>
          <MessageInput
            i18n={i18n.messageInput}
            publish={publish}
            userAndParticipation={userAndParticipation}
            scrollChatToBottom={scrollChatToBottom}
          />
        </MessageInputContainer>
      ) : null}
    </MainContainer>
  );
};

export { TabSocial };
