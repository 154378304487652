import React from 'react';

import { AimTypography, styled } from '@aim/components';
import Divider from '@material-ui/core/Divider';

const CustomDivider = styled(Divider)({
  backgroundColor: 'white !important',
  opacity: '0.3 !important',
});
const SettingsDivider = ({ text }) => {
  return (
    <div>
      <AimTypography
        style={{
          font: 'normal normal normal 20px/24px Roboto',
          marginLeft: 16,
        }}
      >
        {text}
      </AimTypography>
      <CustomDivider variant="middle" />
    </div>
  );
};

export { SettingsDivider };
