import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

let Microphone = (props) => (
  <SvgIcon
    {...props}
    width="13.851"
    height="13.851"
    viewBox="0 0 13.851 13.851"
    style={{
      fill: '#fff',
      fontSize: 40,
      filter: 'drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7))',
      ...props.style,
    }}
  >
    <g transform="translate(0 0)">
      <g transform="translate(0 0)">
        <g transform="translate(0)">
          <path
            d="M395.861,239.305a.54.54,0,0,0,.671-.368,5,5,0,0,0,.2-1.4.541.541,0,0,0-1.082,0,3.919,3.919,0,0,1-.155,1.093A.541.541,0,0,0,395.861,239.305Z"
            transform="translate(-384.772 -230.588)"
          />
          <path
            d="M13.693,12.928.923.159A.541.541,0,0,0,.158.924L4.085,4.85v2.1A2.841,2.841,0,0,0,8.531,9.3l.785.785a3.891,3.891,0,0,1-2.364.794H6.925A3.927,3.927,0,0,1,3,6.953a.541.541,0,0,0-1.082,0,5.012,5.012,0,0,0,4.464,4.976v.841h-1.6a.541.541,0,1,0,0,1.082H9.117a.541.541,0,0,0,0-1.082H7.466v-.838a4.959,4.959,0,0,0,2.621-1.079l2.84,2.84a.541.541,0,1,0,.765-.765ZM6.925,8.711A1.76,1.76,0,0,1,5.167,6.953V5.932L7.744,8.51A1.752,1.752,0,0,1,6.925,8.711Z"
            transform="translate(0 0)"
          />
          <path
            d="M160.288,2.239a.541.541,0,0,0,.729-.232,1.775,1.775,0,0,1,3.334.834V6.168a.541.541,0,0,0,1.082,0V2.841a2.857,2.857,0,0,0-5.377-1.331A.541.541,0,0,0,160.288,2.239Z"
            transform="translate(-155.667)"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default Microphone;
