import React, { useState, useEffect, useMemo } from 'react';


import { useIntl } from 'react-intl';
import translation from './../../utils/translation';

import { styled, theme, CustomIntl } from '@aim/components';

import { TabParticipants } from './tabs/TabParticipants';
import { TabSettings } from './tabs/TabSettings';
import { TabSocial } from './tabs/TabSocial';
import { TabInfo } from './tabs/TabInfo';
import { TabKicked } from './tabs/TabKicked';
import { TabTimeOut } from './tabs/TabTimeOut';

import { extractParticipationUserInfo } from './../../utils/utility';

import { CloseTabs } from './tabs/CloseTabs';

const TabContentContainer = styled('div')({
  color: theme.colors.greyScale.white,
  marginTop: 5,
});

const TabsComponent = ({
  publisherData,
  isMicrophoneOpen,
  handleMute,
  isVideoEnabled,
  toggleVideo,
  subscribers,
  isModerator,
  sendSignal,
  kickUser,
  unpublishUser,
  audioInputs,
  videoInputs,
  currentAudioInput,
  currentVideoInput,
  handleInputAudio,
  handleInputVideo,
  isSoundNotificationActive,
  handleSoundNotification,
  msgChannel,
  uuid,
  userAndParticipation,
  participantsMapImage,
  displayControls,
  fetchDevices,
  selectedTab,
  onClickTabHeader,
  brInfo,
  isKicked,
  isTimeOut,
}) => {
  // Hooks
  const intl = CustomIntl(useIntl());
  const i18nSocial = translation.social(intl);
  const i18n = translation.tabs(intl);

  // States
  const [userMetadata, setUserMetadata] = useState();

  //Effects
  useEffect(() => {
    if (userAndParticipation) {
      const newMetadata = extractParticipationUserInfo(userAndParticipation);
      if (
        !userMetadata ||
        JSON.stringify(userMetadata) !== JSON.stringify(newMetadata)
      ) {
        setUserMetadata(newMetadata);
      }
    }
  }, [userAndParticipation]);

  // Functions
  /* const handleJoin = () => {
    publish({
      type: 'sys',
      text: 'brJoin',
      userInfo: extractParticipationUserInfo(userAndParticipation),
    });
  };

  const handleLeave = () => {
    publish({
      type: 'sys',
      text: 'brLeave',
      userInfo: extractParticipationUserInfo(userAndParticipation),
    });
  }; */

  const renderTab = () => {
    if (isKicked) {
      return <TabKicked />;
    } else if (isTimeOut) {
      return <TabTimeOut />;
    } else {
      switch (selectedTab?.id) {
        case 1:
          return <TabInfo brInfo={brInfo} />;
        case 2:
          return (
            <TabParticipants
              publisherData={publisherData}
              isMicrophoneOpen={isMicrophoneOpen}
              handleMute={handleMute}
              isVideoEnabled={isVideoEnabled}
              toggleVideo={toggleVideo}
              subscribers={subscribers}
              sendSignal={sendSignal}
              isModerator={isModerator}
              kickUser={kickUser}
              unpublishUser={unpublishUser}
              participantsMapImage={participantsMapImage}
              userAndParticipation={userAndParticipation}
            />
          );
        case 3:
          return (
            <TabSocial
              i18n={i18nSocial}
              msgChannel={msgChannel}
              uuid={uuid}
              userAndParticipation={userAndParticipation}
              displayControls={displayControls}
              userMetadata={userMetadata}
            />
          );
        case 4:
          return (
            <TabSettings
              audioInputs={audioInputs}
              videoInputs={videoInputs}
              handleInputAudio={handleInputAudio}
              handleInputVideo={handleInputVideo}
              currentAudioInput={currentAudioInput}
              currentVideoInput={currentVideoInput}
              isSoundNotificationActive={isSoundNotificationActive}
              handleSoundNotification={handleSoundNotification}
              displayControls={displayControls}
              fetchDevices={fetchDevices}
            />
          );

        default:
          break;
      }
    }
  };

  const renderMemoCloseTabs = useMemo(
    () => <CloseTabs onClose={() => onClickTabHeader()} i18n={i18n} />,
    []
  );

  return (
    <>
      {renderMemoCloseTabs}
      <TabContentContainer>{renderTab()}</TabContentContainer>
    </>
  );
};

const Tabs = React.memo(TabsComponent);

export { Tabs };
