import React from 'react';

import { theme, styled, AimIconButton } from '@aim/components';

const variants = {
  BRgray: {
    backgroundColor: '#484848',
    color: `${theme.colors.greyScale.white}`,
    '&:hover': {
      backgroundColor: `${theme.colors.greyScale.grey4}`,
      color: `${theme.colors.greyScale.white}`,
    },
    '&:disabled': {
      color: `${theme.colors.greyScale.white}`,
      backgroundColor: '#484848',
      opacity: 0.4,
    },
  },
  BRred: {
    backgroundColor: `${theme.colors.secondary.red}`,
    color: `${theme.colors.greyScale.white}`,
    '&:hover': {
      backgroundColor: `${theme.colors.dark.red}`,
      color: `${theme.colors.greyScale.white}`,
    },
    '&:disabled': {
      color: `${theme.colors.greyScale.white}`,
      backgroundColor: `${theme.colors.secondary.red}`,
      opacity: 0.4,
    },
  },
  BRtissue: {
    backgroundColor: `#04000073`,
    color: `${theme.colors.greyScale.white}`,
    '&:hover': {
      backgroundColor: `#04000073`,
      color: `${theme.colors.greyScale.white}`,
    },
    '&:disabled': {
      color: `${theme.colors.greyScale.white}`,
      backgroundColor: `#04000073`,
      opacity: 0.4,
    },
  },
};

const CustomIconButton = styled(AimIconButton)(
  ({ variant = 'gray', small = false, style }) => ({
    width: small ? '30px ' : '40px ',
    height: small ? '30px ' : '40px ',
    margin: '4px ',
    borderRadius: '2px ',
    ...(variant && variants[variant]),
    ...(style && style),
  })
);

const InnerBreakoutRoomIcon = ({ children, ...rest }, ref) => (
  <CustomIconButton ref={ref} {...rest} disableRipple disableFocusRipple>
    {children}
  </CustomIconButton>
);

export const BreakoutRoomIcon = React.forwardRef(InnerBreakoutRoomIcon);
