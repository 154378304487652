import React, { useEffect, useState } from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
  useParams,
  useHistory,
} from 'react-router-dom';

import { appState, aws } from '@aim/common';
// import { useZendesk } from '@aim/components';

import { useParticipations } from './utils/participationGqlHelper';

import Breakoutroom from './pages/Breakoutroom';

const Routes = () => {
  // useZendesk();
  const history = useHistory();
  const { path } = useRouteMatch();
  const { eventId } = useParams();
  const { getMyEventParticipation } = useParticipations();
  const [user, setUser] = useState();
  const [isFirstLoadCompleted, setIsFirstLoadCompleted] = useState(false);

  useEffect(() => {
    appState.isSidebarOpen.next(false);
    //appState.selectedMenuItem.next('congress');
  }, []);

  useEffect(() => {
    const subscription = appState.user.subscribe(setUser);
    return () => subscription.unsubscribe();
  }, []);

  const GetUserData = async () => {
    if (!user) {
      const awsUser = await aws.getAwsUser();
      if (awsUser) {
        const userAndParticipation = await getMyEventParticipation(
          awsUser?.username,
          eventId
        );
        if (userAndParticipation) {
          appState.user.next({
            awsUser: awsUser,
            userAndParticipation: userAndParticipation,
          });
        }
      }
      setIsFirstLoadCompleted(true);
    }
    return;
  };

  const CheckUser = () => {
    try {
      if (!isFirstLoadCompleted) return false;
      if (!user?.awsUser && !user?.userAndParticipation?.participation) {
        history.push(`/events/${eventId}/login`);
      }
    } catch (error) {
      console.error(error);
      return false;
    }
    return true;
  };

  GetUserData();

  if (!CheckUser()) return <></>;

  return (
    <Switch>
      <Route exact path={`${path}/:sponsorId/:breakoutroomId`}>
        <Breakoutroom />
      </Route>
    </Switch>
  );
};

export default Routes;
