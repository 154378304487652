import { useState, useEffect, useReducer, useCallback } from 'react';
import OT from '@opentok/client';
import { aws, appState } from '@aim/common';

import worker_script from './../pages/worker';

function talkingReducer(state, action) {
  const nextAudio = { ...state };

  switch (action.type) {
    case 'startTalking':
      nextAudio[action.subscriberId] = new Date().getTime();
      return { ...nextAudio };
    case 'stopTalking':
      return { ...nextAudio };
    default:
      throw new Error();
  }
}

function subscribersReducer(state, action) {
  const nextSubscribers = [...state];

  switch (action.type) {
    case 'join':
      nextSubscribers.push(action.subscriber);
      return nextSubscribers;
    case 'leave': {
      const id = action.event.stream.id;
      const filteredSubscribers = nextSubscribers.filter(
        (s) => s.streamId !== id
      );
      return [...filteredSubscribers];
    }
    case 'streamPropertyChanged': {
      //trigger state refresh
      return nextSubscribers;
    }
    default:
      throw new Error();
  }
}

function publisherReducer(state, action) {
  let nextPublisher = { ...state };

  switch (action.type) {
    case 'set':
      nextPublisher = { ...action.publisher };
      return nextPublisher;
    case 'microphone': {
      nextPublisher?.publishAudio?.(false);

      const nextIsMicrophoneOpen =
        nextPublisher?.getAudioSource()?.enabled ||
        nextPublisher?.getAudioSource()?.muted;

      action.setIsMicrophoneOpen(nextIsMicrophoneOpen);
      return { ...nextPublisher };
    }
    case 'camera':
      nextPublisher?.publishVideo?.(false);
      action.setIsVideoEnabled(false);
      return { ...nextPublisher };

    default:
      throw new Error();
  }
}

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const isInViewport = function (elem) {
  var bounding = elem.getBoundingClientRect();
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <=
      (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const useBreakoutRoom = ({
  breakoutroomId,
  subscribersDivName,
  screenSharingDivName,
  publisherDivName,
  eventId,
  handleError,
  onParticipantJoin,
  onKick,
  onUnpublish,
  selectedLayout,
  carouselDivName,
  layoutListChildStyle,
}) => {
  //State
  const [data, setData] = useState();
  const [subscribers, dispatchSubscriber] = useReducer(subscribersReducer, []);
  const [lastTalkUsers, dispatchTalkEvent] = useReducer(talkingReducer, {});
  const [audioInputs, setAudioInputs] = useState([]);
  const [videoInputs, setVideoInputs] = useState([]);
  const [publisher, dispatchPublisher] = useReducer(publisherReducer, null);
  const [isMicrophoneOpen, setIsMicrophoneOpen] = useState(true);
  const [session, setSession] = useState();
  const [isScreenShared, setIsScreenShared] = useState(false);
  const [screenSharedData, setIsScreenSharedData] = useState();
  const [publisherScreen, setPublisherScreen] = useState();
  const [isVideoEnabled, setIsVideoEnabled] = useState(false);
  const [currentAudioInput, setCurrentAudioInput] = useState('default');
  const [currentVideoInput, setCurrentVideoInput] = useState();
  const [worker, setWorker] = useState();

  //Effects
  useEffect(() => {
    const nextWorker = new Worker(worker_script);
    nextWorker.onmessage = (ev) => {
      const { type, subscriberId } = ev.data;

      if (type && subscriberId) dispatchTalkEvent({ type, subscriberId });
    };
    setWorker(nextWorker);

    return () => nextWorker.terminate();
  }, []);

  useEffect(() => {
    if (breakoutroomId) {
      joinBreakoutRoomLambda();
    }
  }, [breakoutroomId]);

  useEffect(() => {
    const _lastTalkUsers = Object.entries(lastTalkUsers).map(function (x) {
      const item = {
        key: x[0],
        value: x[1],
      };

      return { ...item };
    });

    const parsedSubscribers = subscribers
      .map(function (x) {
        const item = {
          key: x.id,
          value: 0,
        };

        return { ...item };
      })
      .filter((s) => s.key);

    //console.log('👽 lastTalkUsers ', lastTalkUsers);
    //console.log('👽 parsedSubscribers ', parsedSubscribers);

    let result = parsedSubscribers
      .filter((s) => !_lastTalkUsers.find((so) => so.key === s.key))
      .concat(
        _lastTalkUsers.filter((so) =>
          parsedSubscribers.find((s) => so.key === s.key)
        )
      )
      .sort((a, b) => b.value - a.value);

    if (!isScreenShared) {
      result.forEach((i, idx) => {
        if (document && i && i.key && document.getElementById(i.key)) {
          const targetDocument = document.getElementById(i.key);
          const documentStyle = targetDocument.style;

          // serve quando si cambia layout per far ricomparire le persone se sono state nascoste precedentemente
          documentStyle.width = '100%';
          documentStyle.height = '100%';
          documentStyle.display = 'block';
          documentStyle.padding = 0;

          const targetSubscriber = subscribers.find((s) => s.id === i.key);
          if (selectedLayout === 2) {
            applyChildStyle(documentStyle);
            document
              .getElementById(subscribersDivName)
              .appendChild(targetDocument);

            switch (result.length) {
              case 1:
                targetSubscriber?.subscribeToVideo(true);
                targetSubscriber?.setPreferredResolution(null);
                targetSubscriber?.setPreferredFrameRate(null);
                documentStyle.width = '100%';
                break;

              case 2:
              case 3:
                targetSubscriber?.subscribeToVideo(true);
                targetSubscriber?.setPreferredResolution(null);
                targetSubscriber?.setPreferredFrameRate(null);
                documentStyle.width = '50%';

                break;

              default: {
                documentStyle.width = '50%';
                if (idx < 4) {
                  targetSubscriber?.subscribeToVideo(true);

                  targetSubscriber?.setPreferredResolution(null);
                  targetSubscriber?.setPreferredFrameRate(null);
                  documentStyle.display = 'block';
                } else {
                  targetSubscriber?.subscribeToVideo(false);
                  documentStyle.display = 'none';
                }
                break;
              }
            }
          } else {
            if (idx > 0) {
              applyChildStyle(documentStyle);
              targetDocument.setAttribute('key', idx);

              targetSubscriber?.setPreferredResolution({
                width: 320,
                height: 240,
              });

              targetSubscriber?.setPreferredFrameRate(10);

              const subscriberNode = document
                .getElementById(carouselDivName)
                .appendChild(targetDocument);

              // shitty code warning: we should wait for the node to be moved around in the dom before checking if it's in viewport
              setTimeout(() => {
                targetSubscriber?.subscribeToVideo(
                  isInViewport(subscriberNode)
                );
              }, 300);
            } else {
              applyChildStyle(documentStyle, 2);

              targetSubscriber?.setPreferredResolution(null);
              targetSubscriber?.setPreferredFrameRate(null);

              targetSubscriber?.subscribeToVideo(true);

              document
                .getElementById(subscribersDivName)
                .appendChild(targetDocument);
            }
          }
        }
      });
    } else {
      result.forEach((i) => {
        const targetSubscriber = subscribers.find((s) => s.id === i.key);
        targetSubscriber?.subscribeToVideo(false);
      });
    }
  }, [
    lastTalkUsers,
    subscribers,
    selectedLayout,
    isScreenShared,
    layoutListChildStyle,
  ]);

  const applyChildStyle = (elStyle, forceLayout) => {
    for (const [key, value] of Object.entries(layoutListChildStyle)) {
      elStyle[key] = value[forceLayout ?? selectedLayout];
    }
  };

  useEffect(() => {
    fetchDevices();
  }, []);

  useEffect(() => {
    if (!breakoutroomId || !data || publisher) return;

    const { apiKey, sessionId, token } = data;

    const nextSession = OT.initSession(apiKey, sessionId);
    setSession(nextSession);

    createSubscription(nextSession);

    showLoader();
    nextSession.connect(token, (err) => {
      hideLoader();
      if (err) {
        handleError(err);
        return;
      }
      const publisher = createPublisher(nextSession);
      publisher.on({
        accessAllowed: () => {
          fetchDevices();
        },
      });

      publisher.on('streamDestroyed', function (event) {
        if (event.reason === 'forceUnpublished') {
          onUnpublish?.();
        } else if (event.reason === 'forceDisconnected') {
          onKick?.();
        }
      });

      nextSession.publish(publisher, (err) => {
        if (err) handleError(err);
      });
      dispatchPublisher({ type: 'set', publisher });
    });

    return () => {
      try {
        nextSession.off();
        nextSession.disconnect();
      } catch (e) {
        console.error(e);
      }
    };
  }, [breakoutroomId, data]);

  //Callback
  const hideSubscribersOutsideViewport = useCallback(() => {
    subscribers.forEach((s) => {
      const targetDocument = document.getElementById(s.id);
      if (targetDocument) {
        s?.subscribeToVideo(isInViewport(targetDocument));
      }
    });
  }, [subscribers]);

  //Function
  const fetchDevices = () => {
    OT.getDevices((err, devices) => {
      const audioInputs = devices.filter(
        (device) => device.kind === 'audioInput'
      );

      if (
        !audioInputs.find((device) => device.deviceId === currentAudioInput)
      ) {
        handleInputAudio('default');
      }

      const videoInputs = devices.filter(
        (device) => device.kind === 'videoInput'
      );

      if (
        !videoInputs.find((device) => device.deviceId === currentVideoInput) &&
        videoInputs?.length
      ) {
        handleInputVideo(videoInputs[0].deviceId);
      }

      setAudioInputs(audioInputs);
      setVideoInputs(videoInputs);
    });
  };

  const joinBreakoutRoomLambda = async () => {
    showLoader();
    try {
      const apiName = 'vonageServer';
      const path = `/room/${breakoutroomId}?eventId=${eventId}`;
      const response = await aws.standardAPI.get(apiName, path);
      setData(response);
    } catch (e) {
      handleError(e, true);
    } finally {
      hideLoader();
    }
  };

  const createPublisher = (session, videoSource) => {
    const stringData = session?.connection?.data;
    const sessionData = stringData ? JSON.parse(stringData) : {};
    const options = {
      name: `${sessionData.givenName} ${sessionData.familyName}`.trim(),
      fitMode: 'cover',
      showControls: false,
      insertMode: 'append',
      width: '100%',
      height: '100%',
      publishVideo: false,
    };

    if (videoSource) {
      options.videoSource = videoSource;
    }

    return OT.initPublisher(
      publisherDivName,
      {
        ...options,
      },
      (err) => {
        if (err) handleError(err);
      }
    );
  };

  const createSubscription = (session) => {
    session.on('streamCreated', (event) => {
      let domElement = subscribersDivName;
      if (event.stream.videoType === 'screen') {
        domElement = screenSharingDivName;
        setIsScreenShared(true);
        const nextScreenSharedData = event?.stream?.connection?.data || {};
        setIsScreenSharedData(JSON.parse(nextScreenSharedData));
      } else {
        domElement = subscribersDivName;
      }

      const subscriber = session.subscribe(
        event.stream,
        domElement,
        {
          fitMode: event.stream.videoType === 'screen' ? 'contain' : 'cover',
          style: { buttonDisplayMode: 'off', audioBlockedDisplayMode: 'off' },
          insertMode: 'append',
          width: event.stream.videoType === 'screen' ? '100%' : '50%',
          height: '100%',
          subscribeToVideo: event.stream.videoType === 'screen' ? true : false,
        },
        (err) => {
          if (err) handleError(err);
        }
      );

      try {
        subscriber.setStyle('backgroundImageURI', '');

        const target = subscriber.element.querySelector('.OT_video-poster');
        const videoPosterStyle = target.style;
        videoPosterStyle.backgroundColor = '#575757';
        videoPosterStyle.opacity = 1;
        videoPosterStyle.border = '1px solid black';

        const nameSplit = subscriber.stream.name.split(' ') || [];
        const initials = `
          ${nameSplit?.[0]?.substring(0, 1) || ''} 
          ${nameSplit?.[1]?.substring(0, 1) || ''}
        `.toUpperCase();

        const initialsDivContainer = document.createElement('div');
        const initialsText = document.createTextNode(initials);
        initialsDivContainer.appendChild(initialsText);
        initialsDivContainer.style.position = 'absolute';
        initialsDivContainer.style.left = '50%';
        initialsDivContainer.style.top = '50%';
        initialsDivContainer.style.transform = 'translate(-50%, -50%)';
        initialsDivContainer.style.fontSize = '2.5rem';
        initialsDivContainer.style.fontFamily = '"Roboto"';
        target.appendChild(initialsDivContainer);
      } catch (e) {
        console.error(e);
      }

      speakerDetection(subscriber);

      if (event.stream.videoType !== 'screen') {
        dispatchSubscriber({ type: 'join', subscriber });
        onParticipantJoin?.();
      }
    });
    session.on('streamDestroyed', function (event) {
      if (event.stream.videoType === 'screen') {
        setIsScreenShared(false);
      }
      dispatchSubscriber({ type: 'leave', event });
    });
    session.on('streamPropertyChanged', (event) => {
      dispatchSubscriber({ type: 'streamPropertyChanged', event });
    });
    session.on('signal', function (event) {
      const data = event.data;

      switch (data) {
        case 'microphone':
          dispatchPublisher({ type: data, setIsMicrophoneOpen });
          break;
        case 'camera':
          dispatchPublisher({ type: data, setIsVideoEnabled });
          break;
        default:
          break;
      }
    });
  };

  var speakerDetection = function (subscriber) {
    subscriber.on('audioLevelUpdated', function (event) {
      worker.postMessage({
        audioLevel: event.audioLevel,
        subscriberId: subscriber.id,
      });
    });
  };

  const shareScreenFunction = () => {
    if (publisherScreen) {
      publisherScreen.destroy();
      setPublisherScreen(null);
    } else {
      OT.checkScreenSharingCapability((response) => {
        if (!response.supported || response.extensionRegistered === false) {
          // This browser does not support screen sharing.
          // console.log('This browser does not support screen sharing.');
        } else if (response.extensionInstalled === false) {
          // Prompt to install the extension.
        } else {
          // Screen sharing is available. Publish the screen.
          var nextPublisherScreen = OT.initPublisher(
            null,
            {
              videoSource: 'screen',
              insertDefaultUI: false,
            },
            (error) => {
              if (error) {
                handleError(error);
                nextPublisherScreen.destroy();
                setPublisherScreen(null);
              } else {
                session.publish(nextPublisherScreen, function (error) {
                  if (error) {
                    handleError(error);
                    nextPublisherScreen.destroy();
                    setPublisherScreen(null);
                  }
                });
              }
            }
          );
          nextPublisherScreen.on('mediaStopped', function () {
            nextPublisherScreen.destroy();
            setPublisherScreen(null);
          });
          nextPublisherScreen.on('streamDestroyed', function (event) {
            if (
              event.reason === 'mediaStopped' ||
              event.reason === 'forceUnpublished' ||
              event.reason === 'forceDisconnected'
            ) {
              nextPublisherScreen.destroy();
              setPublisherScreen(null);
            }
          });
          setPublisherScreen(nextPublisherScreen);
        }
      });
    }
  };

  const handleMute = () => {
    const nextIsMicrophoneOpen = !isMicrophoneOpen;
    setIsMicrophoneOpen(nextIsMicrophoneOpen);
    publisher && publisher.publishAudio(nextIsMicrophoneOpen);
  };

  const toggleVideo = () => {
    const nextIsVideoEnabled = !isVideoEnabled;
    setIsVideoEnabled(nextIsVideoEnabled);
    publisher && publisher.publishVideo(nextIsVideoEnabled);
  };

  const handleInputAudio = (deviceId) => {
    publisher &&
      publisher
        .setAudioSource(deviceId)
        .then(() => {
          setCurrentAudioInput(deviceId);
        })
        .catch(console.error);
  };

  const handleInputVideo = async (deviceId) => {
    publisher &&
      publisher
        .setVideoSource(deviceId)
        .then(() => {
          setCurrentVideoInput(deviceId);
        })
        .catch(console.error);
  };

  const sendSignal = (subscriber, data) => {
    session.signal(
      {
        data: data,
        to: subscriber.stream.connection,
      },
      function (error) {
        console.error('signal error (' + error?.name + '): ' + error?.message);
      }
    );
  };

  return {
    isMicrophoneOpen,
    audioInputs,
    videoInputs,
    isScreenShared,
    publisherScreen,
    isVideoEnabled,
    publisher,
    subscribers,
    info: data?.info,
    serverTime: data?.serverTime,
    shareScreenFunction,
    handleMute,
    toggleVideo,
    handleInputAudio,
    handleInputVideo,
    sendSignal,
    hideSubscribersOutsideViewport,
    fetchDevices,
    currentAudioInput,
    currentVideoInput,
    screenSharedData,
  };
};
