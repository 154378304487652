import React from 'react';

import { styled, theme, AimTypography } from '@aim/components';

import CloseIcon from '@material-ui/icons/Close';

const CloseIconContainer = React.memo(
  styled('div')({
    color: theme.colors.greyScale.white,
    height: 20,
    position: 'absolute',
    right: 10,
    top: 65,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  })
);

const CloseTabsComponent = ({ onClose, i18n }) => {
  return (
    <CloseIconContainer onClick={onClose}>
      <AimTypography>{i18n.closeText.label}</AimTypography>
      <CloseIcon htmlColor="white" />
    </CloseIconContainer>
  );
};

const CloseTabs = React.memo(CloseTabsComponent);

export { CloseTabs };
